import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images (if any banner or relevant images for “How We Work”)
import bnrImg from "../../images/banner/img_aboutbnr.webp";

class HowWeWork extends Component {
  render() {
    const { isMobileBrowser, isMobileApp, user, setUser, citiesModal, setCitiesModal } = this.props;
    const canonicalUrl = 'https://www.ridenrepair.com/how-we-work';

    return (
      <>
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          <title>How We Work - Ride N Repair</title>
          <meta
            name="description"
            content="Learn how Ride N Repair's doorstep bike service works. From booking online to real-time updates, see how we make two-wheeler maintenance hassle-free."
          />
          <meta
            name="keywords"
            content="How We Work, Ride N Repair, Bike Service App, Doorstep Bike Service, Online Bike Service, Mechanic at Doorstep"
          />
        </Helmet>

        {citiesModal && (
          <AvailableCitiesModal
            closeModal={() => setCitiesModal(false)}
          />
        )}

        <Header
          isMobileBrowser={isMobileBrowser}
          isMobileApp={isMobileApp}
          user={user}
          setUser={setUser}
        />

        <div className="page-content bg-white">
          {/* Banner Section */}
          <div
            className="page-banner ovbl-dark"
            style={{ backgroundImage: `url(${bnrImg})` }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>How We Work</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      How We Work
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          {/* How We Work Content */}
          <section className="section-area section-sp2">
            <div className="container">
              <h2>How Ride N Repair Works</h2>
              <p>
                At Ride N Repair, we make your two-wheeler servicing simple, quick, and hassle-free. Here’s how it works:
              </p>

              <h4>1. Book Your Service Online</h4>
              <p>
                Use our <strong>bike service app</strong> or website to book your <strong>online bike service</strong>.<br />
                Select your vehicle type—whether it’s an Activa, Splendor, Pulsar, or any other model—and the required service (regular maintenance, <strong>bike dent repair</strong>, or emergency repairs).
              </p>

              <h4>2. Choose Your Preferred Location and Time</h4>
              <p>
                Schedule a <strong>doorstep bike service</strong> at your home, office, or any convenient spot. <br />
                Our <strong>mechanic at doorstep</strong> service ensures minimal disruption to your day.
              </p>

              <h4>3. Expert Mechanic Assigned</h4>
              <p>
                A professional, verified mechanic arrives on time with all necessary tools. <br />
                From <strong>Activa mechanic near me</strong> to Royal Enfield or TVS Apache experts, we’ve got you covered.
              </p>

              <h4>4. Service Updates in Real-Time</h4>
              <p>
                Track the progress through our app or website, with transparent pricing and real-time updates about your <strong>two-wheeler repair</strong>.
              </p>

              <h4>5. Your Bike is Ready!</h4>
              <p>
                Once the service is completed, our mechanic conducts a quality check to ensure your bike is in top shape. <br />
                You can then make secure payments online and get your bike ready to ride.
              </p>
            </div>
          </section>
        </div>

        <Footer
          setCitiesModal={setCitiesModal}
          isMobileBrowser={isMobileBrowser}
          isMobileApp={isMobileApp}
        />
      </>
    );
  }
}

export default HowWeWork;
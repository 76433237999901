import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import moment from 'moment-timezone';
import axios from 'axios';
// import * as Location from 'expo-location';
import { DatePicker } from 'react-rainbow-components';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/mechanic-dashboard.css';
import MechanicEntryForm from './mechanic-entry-form';

class MechanicDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment.utc().tz('Asia/Kolkata').startOf('day').format('YYYY-MM-DD'),
      mechanic_phone: null,
      walletBalance: '',
      bookingsData: [],
      mechanic_name: '',
      selectedBookingId: null,
      bookingDetails: {},
      selectedBooking: null,
      modalIsOpen: false,
      selectedPart: '',
      selectedPartsList: [],
      commonParts: '',
      allParts: '',
      invoiceMessage: '',
      completedMessage: '',
      orderStatusMessage: '',
      locationUpdateInterval: null,
      mechanicStatus: '',
      invoiceGenerated: false,
      mechanicInventory: null,
      disableInvoiceButton: false,
      selectedBookingNotes: '',
      notesSuccessMessage: '',
      selectedPhotos: [],
      uploadSuccessMessage: '',
      bookingConfirmations: {},
      showConfirmationModal: false,
      isRequesting: false,
      requestStatus: '',
      submitNotesDisabled: false,
      selectedWorker: '',
      workerDetails: [],
      isWorker: false,
      totalBookingsCount: 0,
      nearbyVendors: [],
      selectedVendor: null,
      isBookingPorter: false,
      porterBookingMessage: '',
      isSendingOtp: false,
      isOtpSent: false,
      enteredOtp: '',
      otpVerificationMessage: '',
      isOtpVerified: false,
    };

    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.submitNotesUpdate = this.submitNotesUpdate.bind(this);
    this.fetchBookingsData = this.fetchBookingsData.bind(this);
    this.callVendor = this.callVendor.bind(this);
    this.bookPorter = this.bookPorter.bind(this);
  }

  componentDidMount() {
    this.props.setShowChatBot(false);
    const { mechanicName, mechanicPhone } = this.props.location.state;
    const dataToSend = { mechanic_phone: mechanicPhone };
    if (typeof window !== 'undefined') {
      window.postMessage(JSON.stringify(dataToSend));
    }

    this.setState(
      {
        mechanic_name: mechanicName,
        mechanic_phone: mechanicPhone,
      },
      async () => {
        this.fetchBookingsData();
        this.fetchPartsData();
        this.checkMechanicWorker();
        const response = await axios.get("https://ridenrepair.com/auto/get_mechanic_status", { params: { mechanic_phone: mechanicPhone }});
        try {
          const inventoryReponse = await axios.get("https://ridenrepair.com/api/get_mechanic_inventory", { params: { mechanic_phone: mechanicPhone }});
          if (inventoryReponse.data && Object.keys(inventoryReponse.data.inventory).length > 0) {
            this.setState({ mechanicInventory: inventoryReponse.data.inventory });
          }
        } catch (error) {
          // console.log('Error fetching inventory')
        }
        const get_balance = await axios.get("https://ridenrepair.com/auto/mechanic_balance", { params: { mechanic_phone: mechanicPhone }});
        this.setState({ mechanicStatus: response.data.status, walletBalance: get_balance.data.balance });

        const get_workers = await axios.get(`https://ridenrepair.com/auto/garage_workers?mechanic_phone=${mechanicPhone}`);

        this.setState({ workerDetails: get_workers.data.workers });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedDate !== prevState.selectedDate) {
      this.fetchBookingsData();
    }
  }

  checkMechanicWorker = async () => {
    const response = await axios.post('https://www.ridenrepair.com/auto/check_mechanic_worker', {
      phone: this.state.mechanic_phone
    });
    this.setState({ isWorker: response.data.message })
  }

  fetchPartsData() {
    fetch('https://ridenrepair.com/api/get_common_parts')
      .then((response) => response.json())
      .then((data) => {
        this.setState({ commonParts: data });
      })
      .catch((error) => {
        console.error('Error fetching common parts data:', error);
      });
    
    fetch('https://ridenrepair.com/api/get_all_parts')
      .then((response) => response.json())
      .then((data) => {
        this.setState({ allParts: data });
      })
      .catch((error) => {
        console.error('Error fetching all parts data:', error);
      });
  }

  fetchBookingsData() {
    const { selectedDate, mechanic_phone } = this.state;

    fetch(`https://ridenrepair.com/api/get_booking_via_mechanic?date=${selectedDate}&mechanic_phone=${mechanic_phone}`)
      .then((response) => response.json())
      .then((data) => {
        // Define the sorting order for order_status
        const orderStatusPriority = ['COMPLETED', 'CASH TAKEN', 'CANCELLED', 'DUPLICATE'];

        // Sort the bookings data based on order_status
        const sortedData = data.sort((a, b) => {
          const statusA = orderStatusPriority.indexOf(a.order_status);
          const statusB = orderStatusPriority.indexOf(b.order_status);

          // Place the known statuses (COMPLETED, CASH TAKEN, etc.) at the end in the specified order
          if (statusA !== -1 && statusB !== -1) {
            return statusA - statusB;
          }
          // If a.statusA is in the array and b.statusA is not, b comes first
          if (statusA !== -1) return 1;
          if (statusB !== -1) return -1;

          // For other statuses, keep the order unchanged
          return 0;
        });

        // Calculate total booking count
        const totalBookingsCount = sortedData.length;

        // Update the state with sorted data and booking count
        this.setState({ bookingsData: sortedData, totalBookingsCount: totalBookingsCount });
      })
      .catch((error) => {
        console.error('Error fetching booking data:', error);
      });
  }

  handleChangeDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    this.setState({ selectedDate: formattedDate });
  };

  handleBookingClick = async (bookingId) => {
    // Fetch nearby vendors
    try {
      const response = await axios.get('https://ridenrepair.com/auto/get_nearby_spare_vendors', {
        params: { mechanic_phone: this.state.mechanic_phone },
      });
      this.setState({ nearbyVendors: response.data });
    } catch (error) {
      console.error('Error fetching nearby vendors:', error);
    };

    this.setState({ modalIsOpen: true });
    const selectedBooking = this.state.bookingsData.find(
      (booking) => booking.booking_id === bookingId
    );
    this.setState({ selectedBooking });
  };

  sendOtpToCustomer = async () => {
    // Immediately disable the button to prevent repeated clicks
    this.setState({ isSendingOtp: true });
  
    try {
      const { selectedBooking } = this.state;
      const response = await axios.post('https://www.ridenrepair.com/api/send_start_service_otp_sms', {
        booking_id: selectedBooking.booking_id,
        phone: selectedBooking.phone,
        booking_source: selectedBooking.booking_source
      });
  
      if (response.status === 200) {
        this.setState({
          isOtpSent: true,
          otpVerificationMessage: 'OTP sent to customer.',
        });
      } else {
        this.setState({
          otpVerificationMessage: 'Failed to send OTP. Please try again.',
        });
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      this.setState({
        otpVerificationMessage: 'Error sending OTP. Please try again.',
      });
    } finally {
      // Re-enable the button after 5 seconds, 
      // but only if OTP was NOT successfully sent (isOtpSent = false).
      // If `isOtpSent = true`, the button is hidden anyway.
      setTimeout(() => {
        // if (!this.state.isOtpSent) {
          this.setState({ isSendingOtp: false });
        // }
      }, 5000);
    }
  };
  
  // Send OTP to customer
  // sendOtpToCustomer = async () => {
  //   this.setState({ isSendingOtp: true });
  //   const { selectedBooking } = this.state;
  //   try {
  //     const response = await axios.post('https://www.ridenrepair.com/api/send_start_service_otp_sms', {
  //       booking_id: selectedBooking.booking_id,
  //       phone: selectedBooking.phone,
  //       booking_source: selectedBooking.booking_source
  //     });

  //     if (response.status === 200) {
  //       this.setState({ isOtpSent: true, otpVerificationMessage: 'OTP sent to customer.' });
  //     } else {
  //       this.setState({ otpVerificationMessage: 'Failed to send OTP. Please try again.' });
  //     }
  //   } catch (error) {
  //     console.error('Error sending OTP:', error);
  //     this.setState({ otpVerificationMessage: 'Error sending OTP. Please try again.' });
  //   }
  // };

  // Verify OTP entered by the mechanic
  verifyOtp = async () => {
    const { enteredOtp, selectedBooking } = this.state;
    try {
      const response = await axios.post('https://www.ridenrepair.com/api/validate_booking_otp', {
        phone: selectedBooking.phone,
        booking_otp: enteredOtp,
      });

      if (response.status === 200 && response.data.is_valid) {
        this.setState({ isOtpVerified: true, otpVerificationMessage: 'OTP verified successfully.' });
        this.updateBookingStatus('SERVICE STARTED');
      } else {
        this.setState({ otpVerificationMessage: 'Invalid OTP. Please try again.' });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      this.setState({ otpVerificationMessage: 'Error verifying OTP. Please try again.' });
    }
  };

  callVendor = () => {
    try {
      const { selectedVendor, mechanic_phone } = this.state;
      const params = {
        mechanic_number: mechanic_phone,
        phone: selectedVendor.phone,
        booking_id: this.state.selectedBooking.booking_id,
      };
      axios.post('https://ridenrepair.com/api/sip_connect_mechanic_to_vendor', params);
    } catch (error) {
      console.error('Error calling vendor:', error);
    }
  };

  bookPorter = async () => {
    this.setState({ isBookingPorter: true, porterBookingMessage: 'Booking Porter...' });
    try {
      const { selectedVendor, mechanic_phone, selectedBooking } = this.state;
      const params = {
        vendor_phone: selectedVendor.phone,
        mechanic_phone: mechanic_phone,
        booking_id: selectedBooking.booking_id
      };
      const response = await axios.post('https://ridenrepair.com/api/create_pickup_order', params);
      if (response.status === 201) {
        this.setState({ porterBookingMessage: 'Porter booked successfully!' });
      } else {
        this.setState({ porterBookingMessage: 'Failed to book porter.' });
      }
    } catch (error) {
      console.error('Error booking porter:', error);
      this.setState({ porterBookingMessage: 'Error booking porter.' });
    } finally {
      // Disable the button for 5 seconds
      setTimeout(() => {
        this.setState({ isBookingPorter: false, porterBookingMessage: '' });
      }, 5000);
    }
  };

  closeModal = () => {
    const { selectedBooking, bookingConfirmations } = this.state;

    const validStatuses = ['IN PROGRESS', 'IN PROGRESS - AUTO', 'IN PROGRESS - TOWED', 'COMPLETED', 'DUPLICATE', 'CANCELLED', 'CASH TAKEN']
    
    if (validStatuses.includes(selectedBooking.order_status)) {
      this.setState({ modalIsOpen: false, selectedBooking: null, selectedPhotos: [], selectedBookingNotes: '' });
    } else if (bookingConfirmations[selectedBooking.booking_id] === undefined) {
      this.setState({ showConfirmationModal: true });
    } else {
      this.setState({ modalIsOpen: false, selectedBooking: null, selectedPhotos: [], selectedBookingNotes: '' });
    }

    this.setState({ 
      selectedPartsList: [],
      selectedVendor: null, 
      nearbyVendors: [], 
      porterBookingMessage: '' 
    });
  };

  handleLogout = () => {
    localStorage.removeItem('authToken');
    const { history } = this.props;
    history.push('/mechanic-login');
  };

  formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const year = date.getUTCFullYear();
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${month} ${day}, ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  handleClickPart = (part) => {
    const { selectedPartsList } = this.state;
    const newPart = {
      partId: part.part_id, 
      partName: part.part_name, 
      salesPrice: '', 
      costPrice: ''
    }
    this.setState({ 
      selectedPart: newPart,
      selectedPartsList: [...selectedPartsList, newPart]
    })
  };

  handleAddOtherPart = () => {
    const { selectedPartsList } = this.state;
    const newPart = {
      partName: '', 
      salesPrice: '', 
      costPrice: ''
    }
    this.setState({ 
      selectedPart: newPart,
      selectedPartsList: [...selectedPartsList, newPart]
    })
  };

  handleOtherPartNameChange = (index, value) => {
    const { selectedPartsList } = this.state;
    const updatedSelectedPartsList = [...selectedPartsList];
    updatedSelectedPartsList[index].partName = value;
    this.setState({
      selectedPartsList: updatedSelectedPartsList
    });
  };

  handleSelectPart = (event) => {
    event.preventDefault();
    const selectedPartId = event.target.options[event.target.selectedIndex].getAttribute('data-part-id');
    const selectedPartName = event.target.options[event.target.selectedIndex].getAttribute('data-part-name');
    const { selectedPartsList } = this.state;
    const newPart = {
      partId: selectedPartId, 
      partName: selectedPartName,
      salesPrice: '', 
      costPrice: ''
    }
    this.setState({ 
      selectedPart: newPart,
      selectedPartsList: [...selectedPartsList, newPart]
    })
  };

  handleNotesChange(event) {
    this.setState({ selectedBookingNotes: event.target.value });
  }

  submitNotesUpdate() {
    const { selectedBookingNotes, selectedBooking } = this.state;
    const { booking_id, email } = selectedBooking;

    this.setState({ submitNotesDisabled: true });

    axios.post('https://ridenrepair.com/api/update_booking_mechanic_notes', {
      booking_id,
      email,
      mechanic_notes: encodeURIComponent(selectedBookingNotes) + ' by ' + this.state.mechanic_name
    })
    .then(response => {
      // Handle success
      // console.log('Notes updated successfully:', response.data);
      this.setState({ notesSuccessMessage: 'Notes updated successfully!' });
    })
    .catch(error => {
      // Handle error
      // console.log('There was an error updating the notes:', error);
      this.setState({ notesSuccessMessage: 'Failed to update notes. Please try again.' });
    })
    .finally(() => {
      // Enable the button after 3 seconds
      setTimeout(() => {
        this.setState({ submitNotesDisabled: false });
      }, 3000);
    });
  }

  handleQuantityChange = (index, quantity) => {
    const { selectedPartsList } = this.state;
    const updatedSelectedPartsList = [...selectedPartsList];
    updatedSelectedPartsList[index].quantity = quantity;
    this.setState({ selectedPartsList: updatedSelectedPartsList });
  };
  
  removePart = (index) => {
    const { selectedPartsList } = this.state;
    const updatedSelectedPartsList = [...selectedPartsList];
    updatedSelectedPartsList.splice(index, 1);
    this.setState({ selectedPartsList: updatedSelectedPartsList });
  };

  handleSalesPriceChange = (index, salesPrice) => {
    const { selectedPartsList } = this.state;
    const updatedSelectedPartsList = [...selectedPartsList];
    updatedSelectedPartsList[index].salesPrice = salesPrice;
    this.setState({ selectedPartsList: updatedSelectedPartsList });
  };

  requestNewBooking = () => {
    const params = {
      mechanic_phone: this.state.mechanic_phone
    }
    
    axios.post('https://ridenrepair.com/auto/booking_request_by_mechanic', params)
      .then(response => {
        // Check if booking_id is present in the response data
        if (response.data.booking_id) {
          this.setState({ requestStatus: 'New booking successfully requested!' });
          // Fetch updated bookings data or update UI as needed
          this.fetchBookingsData();
        } else {
          this.setState({ requestStatus: 'No booking found.' });
        }
      })
      .catch(error => {
        this.setState({ requestStatus: 'Error: Unable to request booking.' });
      })
      .finally(() => {
        this.setState({ isRequesting: false });
        // Clear requestStatus after 20 seconds
        setTimeout(() => {
          this.setState({ requestStatus: '' });
        }, 20000); // 20 seconds in milliseconds
      });
  }
  
  // handleCostPriceChange = (index, costPrice) => {
  //   const { selectedPartsList } = this.state;
  //   const updatedSelectedPartsList = [...selectedPartsList];
  //   updatedSelectedPartsList[index].costPrice = costPrice;
  //   this.setState({ selectedPartsList: updatedSelectedPartsList });
  // };

  callCustomer = () => {
    try {
      this.setState({ invoiceMessage: '', completedMessage: '', orderStatusMessage: '' });
      const { selectedBooking, mechanic_phone } = this.state;
      const params = {
        mechanic_number: mechanic_phone,
        phone: selectedBooking.phone,
        booking_id: selectedBooking.booking_id
      }
      axios.post(`https://ridenrepair.com/api/sip_connect_mechanic_to_customer`, params);
    } catch (error) {
      console.error('calling customer error: ', error);
    }
  }

  updateBookingStatus = (order_status) => {
    const { selectedBooking, bookingsData } = this.state;
    const params = {
      booking_id: selectedBooking.booking_id,
      email: selectedBooking.email,
      order_status: order_status, 
      change_history: 'Status updated by ' + this.state.mechanic_name
    }

    // Update the order status in the bookingsData state
    const updatedBookingsData = bookingsData.map(booking => {
      if (booking.booking_id === selectedBooking.booking_id) {
        return {
          ...booking,
          order_status: order_status
        };
      }
      return booking;
    });

    // Update the state with the modified bookingsData
    this.setState({ bookingsData: updatedBookingsData });

    if (order_status === 'COMPLETED' || order_status === 'CASH TAKEN') {
      const smsCustFeedbackParams = {
        phone: selectedBooking.phone
      }

      try {
        axios.post("https://ridenrepair.com/auto/reduce_mechanic_daily_count", { mechanic_phone: selectedBooking['mechanic_number'] });
      } catch (error) {
          // console.log('Unable to update mechanic')
      }
      try {
          axios.post('https://ridenrepair.com/api/send_customer_feedback_sms', smsCustFeedbackParams);
      } catch (error) {
          // console.log('Error sending customer feedback sms');
      }
      this.setState({ completedMessage: 'Booking Completed' })
      this.setState({ orderStatusMessage: ''})
    } else {
      this.setState({ completedMessage: '' })
      this.setState({ orderStatusMessage: order_status })
    }
    if (order_status === 'IN PROGRESS - TOWED') {
      try {
        const towedEmailParams = {
          email: selectedBooking['email'],
          first_name: selectedBooking['first_name'],
          mechanic_name: selectedBooking['mechanic_name'],
          vehicle_name: selectedBooking['vehicle_name']
        }
        axios.post("https://ridenrepair.com/api/sendtowedemail", towedEmailParams);
      } catch (error) {
        // console.log('Unable to send towing email to customer')
      }

      try {
        axios.post("https://ridenrepair.com/auto/reduce_mechanic_daily_count", { mechanic_phone: this.state.mechanic_phone });
      } catch (error) {
          // console.log('Unable to update mechanic')
      }
    }
    try {
      axios.post(`https://ridenrepair.com/api/update_booking_status`, params);
    } catch (error) {
      // console.error('update booking status error: ', error);
    }
    try {
      axios.post(`https://ridenrepair.com/api/update_booking_change_history`, params);
    } catch (error) {
      // console.error('update booking status error: ', error);
    }
  }

  updateMechanicStatus = (status) => {
    this.setState({ mechanicStatus: status })
    const params = {
      phone: this.state.mechanic_phone,
      status: status
    }
    try {
      axios.post('https://ridenrepair.com/auto/mechanic_status', params)
    } catch (error) {
      // console.log('Unable to update mechanic status')
    }
  }

  generateInvoice = async () => {
    this.setState({ invoiceMessage: 'Generating Invoice', disableInvoiceButton: true });
    if (!this.state.invoiceGenerated || this.state.disableInvoiceButton) {
      try {
        const { selectedBooking, selectedPartsList } = this.state;
        if (selectedPartsList.some((part) => !part.salesPrice)) {
          this.setState({ invoiceMessage: 'Please fill in all the Sales prices' });
          return; // Exit the function if salesPrice is missing for any part
        }
        const partsNames = selectedPartsList.map((part) => part.partName);
        const salesPrices = selectedPartsList.map((part) => part.salesPrice);

        let email = '';
        if (selectedBooking.secondary_email) {
          email = selectedBooking.secondary_email;
        } else {
          email = selectedBooking.email;
        }
        const params = {
          booking_id: selectedBooking.booking_id,
          phone: selectedBooking.phone,
          email: email,
          charges_desc: partsNames.join(','),
          amount_details: salesPrices.join(','),
          paid_amount: selectedBooking.paid_amount,
        };
        
        const paymentLink = await axios.get("https://ridenrepair.com/api/get_rzp_pay_link", { params });

        selectedBooking['payment_link'] = paymentLink.data.short_url;
        selectedBooking['payment_link_id'] = paymentLink.data.payment_link_id;
        selectedBooking['final_payment_amount'] = paymentLink.data.final_amount;
        selectedBooking['invoice_id'] = paymentLink.data.invoice_id;
        selectedBooking['charges_desc'] = params.charges_desc;
        selectedBooking['amount_details'] = params.amount_details;
        
        if (paymentLink.data.prepaid) {
          const mechanicparams = {
            phone: selectedBooking['mechanic_number'],
            status: 'AVAILABLE',
          }
          try {
              axios.post("https://ridenrepair.com/auto/mechanic_status", mechanicparams);
          } catch (error) {
              console.error('update mechanic status error: ', error);
          }
        } else {
          try {
              axios.post(`https://ridenrepair.com/api/update_booking_invoice`, selectedBooking);
          } catch (error) {
              console.error('update booking invoice error: ', error);
          }
          try {
              axios.post("https://ridenrepair.com/whatsapp/sendpaylink", selectedBooking);
          } catch (error) {
              console.error('sending whatsapp payment link error: ', error);
          }
          const mechanic_params = {
              phone: selectedBooking['mechanic_number'],
              status: 'AVAILABLE',
          }
          try {
              axios.post("https://ridenrepair.com/auto/mechanic_status", mechanic_params);
          } catch (error) {
              console.error('update mechanic status error: ', error);
          }
        }
        this.setState({ selectedBooking });
        this.setState({ invoiceMessage: 'Invoice Generated' });
        this.setState({ invoiceGenerated: true });

        // Set a timeout to re-enable the button after 5 seconds
        setTimeout(() => {
          this.setState({ disableInvoiceButton: false });
        }, 5000);
      } catch (error) {
        console.error('Error in generateInvoice:', error);
      }
    }
  }

  handlePhotoUpload = (event) => {
    const files = Array.from(event.target.files);
    this.setState((prevState) => ({
      selectedPhotos: [...prevState.selectedPhotos, ...files],
    }));
  };

  closeConfirmationModal = (isGoing) => {
    const { selectedBooking } = this.state;
    const updatedConfirmations = {
      ...this.state.bookingConfirmations,
      [selectedBooking.booking_id]: isGoing,
    };
    
    this.setState({
      bookingConfirmations: updatedConfirmations,
      showConfirmationModal: false,
    }, () => this.closeModal());
  };

  handleBookingDenial = () => {
    const { selectedBooking, mechanic_name, mechanic_phone } = this.state;

    const params = {
      booking_id: selectedBooking.booking_id,
      email: selectedBooking.email,
      mechanic_phone: mechanic_phone,
      mechanic_name: mechanic_name,
      order_status: 'MECHANIC DENIED'
    };

    try {
      axios.post('https://ridenrepair.com/api/update_booking_status', params);
    } catch (error) {
      // console.log('Unable to update booking status');
    }

    try {
      axios.post('https://ridenrepair.com/auto/mechanic_reallocate', params);
    } catch (error) {
      // console.log('Unable to allocate mechanic');
    }

    this.closeConfirmationModal(false);

  }
  
  uploadPhotos = async () => {
    const { selectedBooking, selectedPhotos } = this.state;
  
    const formData = new FormData();
    
    formData.append('booking_id', selectedBooking.booking_id);
    formData.append('email', selectedBooking.email);
    selectedPhotos.forEach((file) => {
      formData.append('photos', file);
    });

    try {
      const response = await axios.post('https://www.ridenrepair.com/api/upload_booking_photo', formData);

      if (response.status === 200) {
        this.setState({ uploadSuccessMessage: 'Photos uploaded successfully!' });
      } else {
        this.setState({ uploadSuccessMessage: 'Failed to upload photos.' });
      }
    } catch (error) {
      console.error('Error uploading photos:', error);
      this.setState({ uploadSuccessMessage: 'Error uploading photos.' });
    }
  };

  capitalizeFirstLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  onSuccessfulPayment = (data) => {
    const params = {
      mechanic_number: this.state.mechanic_phone,
      adjustment: parseFloat(data.amount) / 100
    };

    axios.post(`https://ridenrepair.com/auto/update_mechanic_balance`, params);
  }

  loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

  assignWorker = (selectedWorker) => {
    const { selectedBooking } = this.state;

    if (!selectedBooking) {
      return;
    }

    const params = {
      booking_id: selectedBooking.booking_id,
      email: selectedBooking.email,
      worker_name: selectedWorker.worker_name,
      worker_number: selectedWorker.worker_number
    };
    
    axios.post(`https://ridenrepair.com/api/update_booking_worker_mechanic`, params);
  }

  displayRazorpay = async (amount) => {
		const res = await this.loadScript(
			"https://checkout.razorpay.com/v1/checkout.js"
		);

		if (!res) {
			alert("Razorpay SDK failed to load. Are you online?");
			return;
		}

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ amount: amount * 100, currency: 'INR' })
		};

		const data = await fetch('https://ridenrepair.com/api/razorpay', requestOptions).then(
			(response) => response.json()
		)

		const options = {
			key: 'rzp_live_v9Y9ZhDUWFgjNI',
			// key: 'rzp_live_JiAxnDUo1qXebr', // Enter the Key ID generated from the Dashboard
			// key: 'rzp_test_K9AJpedBWJr6ah',
			amount: amount,
			currency: data.currency,
			name: 'Ride N Repair',
			description: 'Mechanic Payment',
				image: 'https://ridenrepair.com/api/logo.png',
				order_id: data.id,
				handler: (response) => {this.onSuccessfulPayment(data); },
				prefill: {
					name: this.state.mechanic_name,
					contact: this.state.mechanic_phone,
				},
				theme: {
					color: "#61dafb",
				},
		};
		
		if (typeof window !== 'undefined') {
			const paymentObject = new window.Razorpay(options);
			paymentObject.open();
		}
	}

  render() {
    const {
      selectedDate,
      bookingsData,
      mechanic_name,
      mechanic_phone,
      selectedBooking,
      modalIsOpen,
      bookingConfirmations,
      commonParts, 
      allParts, 
      selectedPart, 
      selectedPartsList,
      selectedBookingNotes,
      notesSuccessMessage,
      uploadSuccessMessage,
      selectedPhotos,
      showConfirmationModal,
      isRequesting,
      requestStatus,
      mechanicStatus,
      walletBalance,
      mechanicInventory,
      submitNotesDisabled,
      selectedWorker,
      workerDetails,
      isWorker,
      totalBookingsCount,
      nearbyVendors,
      selectedVendor
    } = this.state;

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backdropFilter: 'blur(8px)',
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'auto',
        textAlign: 'center',
      },
    };

    return (
      <div className="mechanic-dashboard">
        <header>
          <h2>Hello {mechanic_name}</h2>
          <center>
            <button className="logout-button" onClick={this.handleLogout}>
              Logout
            </button>
          </center>
        </header>
        <br />
        {(mechanic_phone === '9811039250') && <MechanicEntryForm />}
        <br />
        {mechanicInventory && 
          <div style={{ 'display': 'inline-flex', 'color': 'black' }}>
            <h6>Inventory:</h6>&nbsp;&nbsp; <div>Engine Oil - {mechanicInventory["Engine Oil"]}</div>
          </div>
        }
        <br /> <br />
        <div style={{ 'display': 'inline-flex', 'color': 'black' }}>
          <h6>Wallet Balance:</h6>&nbsp;&nbsp; Rs.&nbsp;<div>{walletBalance}</div>&nbsp;&nbsp;
          {walletBalance < 0 && <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={() => this.displayRazorpay(parseFloat(walletBalance) * -1)}>Pay Rs. {parseFloat(walletBalance) * -1}</button>}
        </div>
        <br /> 
        <div style={{ 'display': 'inline-flex', 'color': 'black' }}>
          <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={this.fetchBookingsData}>Get My Bookings</button>
        </div>
        <br /> <br />
        <div style={{ 'color': 'black' }}>
          <h6>Current Status:</h6>&nbsp;&nbsp; <div>{mechanicStatus}</div>&nbsp;&nbsp;
          <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={() => this.updateMechanicStatus('AVAILABLE')}>Mark Available</button>
          <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={() => this.updateMechanicStatus('BUSY')}>Mark Busy</button>
        </div>
        <br/>
        <div style={{ 'display': 'inline-flex', 'color': 'black' }}>
          <h6>Total Bookings:</h6>&nbsp;&nbsp; <div>{totalBookingsCount}</div>
        </div>
        <br /> <br />
        { !isWorker && 
          <div style={{ 'display': 'inline-flex', 'color': 'black' }}>
            <button 
              className="logout-button" 
              style={{ 'margin': '5px', 'padding': '5px 5px' }} 
              onClick={this.requestNewBooking}
              disabled={isRequesting}
            >
              {isRequesting ? 'Requesting new booking...' : 'Request New Booking'}
            </button>
            {requestStatus && <p>{requestStatus}</p>}
          </div>
        }
        <br /> <br />
        <div className="date-div">
          Booking Date:
          <DatePicker 
              className="form-control"
              value={selectedDate}
              onChange={this.handleChangeDate}
              showYearDropdown
          />
        </div>
        <br /> <br /> <br />
        {bookingsData.map((booking) => (
          <div key={booking.booking_id} className="booking-card">
            <p
              className="booking-id"
              onClick={() => this.handleBookingClick(booking.booking_id)}
            >
              Booking ID: {booking.booking_id} (Click for details)
            </p>
            <p className="service-type">Service Type: {booking.service_type}</p>
            <p>Customer Name: {booking.first_name}</p>
            {/* {booking.assign_buddy && <p>Ride N Repair POC: {this.capitalizeFirstLetter(booking.assign_buddy)}</p>} */}
            <p className="time">Date: {booking.date_of_service}</p>
            <p className="time">Time: {booking.time_of_service}</p>
            <p>Order Status: {booking.order_status}</p>
            <p>Total Amount: {booking.final_payment_amount ? Math.round(parseInt(booking.final_payment_amount)) : ''}</p>
          </div>
        ))}
        {selectedBooking && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Booking Details"
          >
            <div style={{ width: '80%', paddingLeft: '20%' }}>
              <center>
                <h3>Booking Details</h3>
              </center>
              <p>Booking ID: {selectedBooking.booking_id}</p>
              <p>Date of Service: {selectedBooking.date_of_service}</p>
              <p>Time of Service: {selectedBooking.time_of_service}</p>
              <p>Order Status: {selectedBooking.order_status}</p>
              <p>Vehicle Name: {selectedBooking.vehicle_name}</p>
              <p>Service Type: {selectedBooking.service_type}</p>
              <p>Additional Details: {selectedBooking.additional_details}</p>
              {/* Display customer info and Google Maps link if not cancelled or completed */}
              { selectedBooking.order_status !== 'CANCELLED' && selectedBooking.order_status !== 'DUPLICATE' && selectedBooking.order_status !== 'COMPLETED' && selectedBooking.order_status !== 'CASH TAKEN' && (
                <div>
                  <p>Customer Name: {selectedBooking.first_name}</p>
                  {/* Confirm Start Service OTP Flow */}
                  {selectedBooking.order_status !== 'SERVICE STARTED' && (
                    <div>
                      {!this.state.isOtpSent ? (
                        <button
                          className="logout-button"
                          style={{ margin: '10px', padding: '5px 10px' }}
                          onClick={this.sendOtpToCustomer}
                          disabled={this.state.isSendingOtp}
                        >
                          Confirm Start Service
                        </button>
                      ) : (
                        <div>
                          <p style={{ color: 'green' }}>{this.state.otpVerificationMessage}</p>
                          <input
                            type="text"
                            placeholder="Enter OTP"
                            value={this.state.enteredOtp}
                            onChange={(e) => this.setState({ enteredOtp: e.target.value })}
                            style={{ margin: '10px', padding: '5px', width: '150px' }}
                          />
                          <button
                            className="logout-button"
                            style={{ margin: '5px', padding: '5px 10px' }}
                            onClick={this.verifyOtp}
                          >
                            Start Service
                          </button>
                        </div>
                      )}
                      {this.state.isOtpVerified && (
                        <p style={{ color: 'green', marginTop: '10px' }}>Service Started Successfully!</p>
                      )}
                    </div>
                  )}
                  {/* {selectedBooking.assign_buddy && <p>Ride N Repair POC: {this.capitalizeFirstLetter(selectedBooking.assign_buddy)}</p>} */}
                  {workerDetails.length > 0 && (
                    <div style={{ margin: '5px 0' }}>
                      <label htmlFor="worker-select">Assign Worker:</label>
                      <select
                        id="worker-select"
                        value={selectedWorker.worker_number || ""}
                        className='vendor-select'
                        onChange={(e) => {
                          const selectedOption = workerDetails.find(worker => worker.phone === e.target.value);
                          this.setState({
                            selectedWorker: selectedOption ? {
                              worker_number: selectedOption.phone,
                              worker_name: selectedOption.name
                            } : {}
                          });
                        }}
                        style={{ margin: '0 10px', maxWidth: '200px' }}
                      >
                        <option value="" disabled>Select a worker</option>
                        {workerDetails.map((worker) => (
                          <option key={worker.phone} value={worker.phone}>
                            {worker.name}
                          </option>
                        ))}
                      </select>
                      <button
                        className="logout-button"
                        style={{ 'padding': '5px', 'margin-left': '10px' }}
                        onClick={() => this.assignWorker(selectedWorker)}
                        disabled={!selectedWorker.worker_number}
                      >
                        Assign Booking
                      </button>
                    </div>
                  )}
                  <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={() => this.callCustomer()}>Call Customer</button>
                  <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={() => { this.updateBookingStatus('IN PROGRESS'); }}>Booking Confirmed</button>
                  <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={() => { this.updateBookingStatus('NO RESPONSE'); }}>Customer did not Pick</button>
                  <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={() => { this.updateBookingStatus('MECHANIC REACHED'); }}>Reached Customer</button>
                  <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={() => { this.updateBookingStatus('IN PROGRESS - TOWED'); }}>Vehicle Towed</button>
                  <p>Address: {selectedBooking.address}</p>
                  <p>
                    <a
                      // `geo:${selectedBooking.latitude},${selectedBooking.longitude}`
                      href={`https://www.google.com/maps/dir/?api=1&destination=${selectedBooking.latitude},${selectedBooking.longitude}&travelmode=driving`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open Customer Location
                    </a>
                  </p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input 
                      style={{ maxWidth: '200px' }}
                      type="text" 
                      name="mechanic_notes" 
                      value={selectedBookingNotes} 
                      placeholder='Enter notes' 
                      onChange={this.handleNotesChange} 
                      disabled={submitNotesDisabled}
                    />&nbsp;&nbsp;&nbsp;&nbsp;
                    <button onClick={this.submitNotesUpdate} disabled={submitNotesDisabled}>Update Notes</button>
                    {notesSuccessMessage && <p>{notesSuccessMessage}</p>} {/* Conditionally render the success message */}
                  </div>

                  {/* Vendor Selection Section */}
                  { nearbyVendors && nearbyVendors.length > 0 && <div className='vendor-select'>
                    <h6>Select a Vendor:</h6>
                    <select
                      value={selectedVendor ? selectedVendor.phone : ''}
                      onChange={(e) => {
                        const selectedPhone = e.target.value;
                        const selectedVendor = nearbyVendors.find(
                          (vendor) => vendor.phone === selectedPhone
                        );
                        this.setState({ selectedVendor });
                      }}
                    >
                      <option value="">Select a vendor</option>
                      {nearbyVendors.map((vendor) => (
                        <option key={vendor.phone} value={vendor.phone}>
                          {vendor.name} - {vendor.distance.toFixed(2)} km away
                        </option>
                      ))}
                    </select>
                    {selectedVendor && (
                      <div>
                        <button
                          className="logout-button"
                          style={{ margin: '5px', padding: '5px 5px' }}
                          onClick={this.callVendor}
                        >
                          Call {selectedVendor.name}
                        </button>
                        <button
                          className="logout-button"
                          style={{ margin: '5px', padding: '5px 5px' }}
                          onClick={this.bookPorter}
                          disabled={this.state.isBookingPorter}
                        >
                          {this.state.isBookingPorter ? 'Booking Porter...' : 'Book Porter'}
                        </button>
                        {this.state.porterBookingMessage && (
                          <p>{this.state.porterBookingMessage}</p>
                        )}
                      </div>
                    )}
                  </div> }

                  {/* Upload Photos Section */}
                  <div>
                    <br/>
                    <h6>Upload Photos:</h6>
                    <input style={{ maxWidth: '400px' }} type="file" multiple onChange={this.handlePhotoUpload} /><br/> <br/>
                    <div>
                      {selectedPhotos.length > 0 && (
                        <div>
                          <h5>Selected Photos:</h5>
                          <ul>
                            {selectedPhotos.map((file, index) => (
                              <li key={index}>{file.name}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <button onClick={this.uploadPhotos}>Upload Photos</button>
                    {uploadSuccessMessage && <p>{uploadSuccessMessage}</p>}
                  </div>

                  <br/> 
                  
                  <button className="logout-button" style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={() => this.updateBookingStatus('CASH TAKEN')}>
                    Cash Taken
                  </button>
                  <br/>

                  <div>
                    <div>
                      <h6>Common Parts:</h6>
                      {commonParts && commonParts.map((part) => (
                        <button key={part.part_id} style={{ 'margin': '5px' }} onClick={() => this.handleClickPart(part)}>
                          {part.part_name}
                        </button>
                      ))}
                      <button style={{ 'margin': '5px' }} onClick={this.handleAddOtherPart}>
                        Other
                      </button>
                    </div>
                    <br/>
                    <div>
                      <h6>All Parts:</h6>
                      <select value={selectedPart} onChange={this.handleSelectPart}>
                        <option value="">Select a part</option>
                        {allParts && allParts.map((part) => (
                          <option 
                            key={part.part_id} 
                            data-part-id={part.part_id}
                            data-part-name={part.part_name}
                            value={part.part_name}
                          >
                            {part.part_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <br/>
                    {selectedPartsList.length > 0 && (
                      <div>
                        <h6>Selected Parts:</h6>
                        <div className='table-container'>
                          <table className='part-table'>
                            <thead>
                              <tr>
                                <th>Part</th>
                                <th>Price</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedPartsList.map((selectedPart, index) => (
                                <tr key={index} style={{ 'border-bottom': '1px solid #ccc' }}>
                                  <td style={{ 'padding': '8px' }}>
                                    <input
                                      type="text"
                                      placeholder="Enter Part Name"
                                      value={selectedPart.partName}
                                      style={{ minWidth: '150px', width: `${Math.max(selectedPart.partName.length * 8, 150)}px` }}
                                      onChange={(e) => this.handleOtherPartNameChange(index, e.target.value)}
                                    />
                                  </td>
                                  <td style={{ 'padding': '8px' }}>
                                    <input
                                      type="number"
                                      placeholder="Sales Price"
                                      value={selectedPart.salesPrice}
                                      onChange={(e) => this.handleSalesPriceChange(index, e.target.value)}
                                      onKeyDown={(e) => {
                                        // Prevent typing of minus sign if input value is empty or already negative
                                        if (e.key === '-' && (!selectedPart.salesPrice || selectedPart.salesPrice.startsWith('-'))) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </td>
                                  <td style={{ 'padding': '8px' }}>
                                    <button className="logout-button" style={{ 'padding': '5px 5px' }} onClick={() => this.removePart(index)}>
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan="3" style={{ textAlign: 'right', paddingRight: '8px' }}>Total Amount:</td>
                                <td style={{ padding: '8px' }}>
                                  {selectedPartsList.reduce((total, part) => total + parseFloat(part.salesPrice || 0), 0)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                  <br/>
                  {this.state.invoiceMessage || this.state.completedMessage}
                  <center>
                    <button className={`logout-button${this.state.disableInvoiceButton ? ' disabled-button' : ''}`} style={{ 'margin': '5px', 'padding': '5px 5px' }} onClick={this.generateInvoice}>
                      Generate Invoice
                    </button>
                  </center>

                  {/* Mechanic Confirmation Prompt */}
                  {showConfirmationModal && selectedBooking && !bookingConfirmations[selectedBooking.booking_id] && !['IN PROGRESS', 'IN PROGRESS - AUTO', 'IN PROGRESS - TOWED'].includes(selectedBooking.order_status) && (
                    ReactDOM.createPortal(
                      <div className="confirmation-modal">
                        <h3>Confirm Booking</h3>
                        <p>Are you going to this booking?</p>
                        <button style={{ margin: '10px' }} onClick={() => { this.updateBookingStatus('MECHANIC CONFIRMED'); this.closeConfirmationModal(true)} }>Yes, I am going to this booking</button>
                        <button onClick={() => this.handleBookingDenial()}>No, give this booking to another mechanic</button>
                      </div>,
                      document.body
                    )
                  )}
                </div>
              )}

              {/* Display additional details for COMPLETED bookings */}
              {(selectedBooking.order_status === 'COMPLETED' || selectedBooking.order_status === 'CASH TAKEN') && (
                <div>
                  <p>Charges Description:</p>
                  <ul>
                    {selectedBooking.charges_desc && selectedBooking.charges_desc.split(',').map((item, index) => (
                      <li key={index}>
                        {item}: {(parseFloat(selectedBooking.amount_details.split(',')[index])).toFixed(1)}
                      </li>
                    ))}
                  </ul>
                  <p>Completed On: {this.formatDate(selectedBooking.completed_on)}</p>
                </div>
              )}
              <center>
                <button className="logout-button" onClick={this.closeModal}>
                  Close
                </button>
              </center>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default MechanicDashboard;

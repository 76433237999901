import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HashMap from 'hashmap';
// import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import axios from "axios";
import '../../../src/Calendar.scss';
import Header from "../layout/header";
import Footer from "../layout/footer";
// import ReactPixel from 'react-facebook-pixel';
import Modal from '../elements/modal';
import Places from '../elements/places';
import smoothscroll from 'smoothscroll-polyfill';
import { Helmet } from 'react-helmet';
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images
import bnrImg from "../../images/banner/bnr8.jpg";

class Booking extends Component {

	dateFormat = null;
	ReactPixel = null;

	state = {
		isClient: false,
		isMobileApp: false,
		vehicle_category: '',
		vehicleyear: '',
		vehiclename: '',
		vehiclemileage: '',
		service: false,
		serviceName: '',
		selectedService: '',
		price: '',
		coupon: '',
		couponApplied: false,
		bookingError: '',
		locationAddress: '',
		homeAddress: '',
		city: '',
		state: '',
		zip: '',
		email: '',
		latitude: '',
		longitude: '',
		phone: '',
		datetimeError: '',
		emailError: '',
		phoneError: '',
		name: '',
		addtnl_details: '',
		isCODButtonVisible: true,
		selectedLocation: { lat:'', lng:'' },
		metaEventData: [],
		date_of_service: '',
		time_of_service: '',
		is_phone_verified: false,
		is_email_verified: false,
		booking_otp: '',
		email_otp: '',
		show_enter_otp_box: false,
		show_enter_email_otp_box: false,
		years: [],
		vehicle_data: [],
    vehicle_price_details: new HashMap(),
		brandNames: [],
		ccOptions: [],
		vehicleOptions: [],
    selectedBrand: '',
    selectedCCRange: '',
    filteredVehicleNames: [],
		selectedVehicle: '',
		userAddresses: [],
		selectedAddress: '',
		booking_in_progress: false
	};

	sendMessage = (params) => {
		axios.post("https://ridenrepair.com/auto/send_booking_confirmation", params)
		.then((res) => {
			if (res.status === 200) {
			} else {
				console.log("Booking confirmation error");
			}
		});
	}

	validatePhone = () => {
		const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
		const isValidPhone = phoneRegex.test(this.state.phone);
		if (isValidPhone) {
			// phone is valid
			this.setState({ phoneError: '' });
		} else {
			// phone is invalid
			this.setState({ phoneError: 'Invalid Phone Number' });
		}
	}

	validateEmail = () => {
		const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/;
		// const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		const isValidEmail = emailRegex.test(this.state.email);
		if (isValidEmail) {
			// email is valid
			this.setState({ emailError: '' });
		} else {
			// email is invalid
			this.setState({ emailError: 'Invalid Email' });
		}
	}

	handleChangeDateWithEvent = (newDate, event) => {
		if (event.validationError !== null) {
			this.setState({ datetimeError: 'Invalid Date or Time' })
			return;
		} else {
			this.setState({ datetimeError: '' })
			this.setState({ date_of_service: this.dateFormat(newDate, 'yyyy-mm-dd') });
			if (typeof window !== 'undefined') {
				window.dataLayer.push({
					event: 'ServiceDate'
				});
			}
		}
	}

	handleAddressChange = (event) => {
		const selectedAddressIndex = event.target.value;
		// Check if the selected address is not the "Choose a new address" option
		if (selectedAddressIndex !== "") {
			// Find the selected address from the list of userAddresses
			const selectedAddressObj = this.state.userAddresses[selectedAddressIndex];
			// Update state with the selected address details
			this.setState({ 
				selectedAddress: selectedAddressIndex,
				homeAddress: selectedAddressObj.address,
				city: selectedAddressObj.city,
				state: selectedAddressObj.state,
				zip: selectedAddressObj.zip,
				latitude: selectedAddressObj.latitude,
				longitude: selectedAddressObj.longitude
			});
		} else {
			// Reset the address details when "Choose a new address" is selected
			this.setState({ 
				selectedAddress: selectedAddressIndex,
				homeAddress: "", // Reset the address
				city: "",       // Reset the city
				state: "",      // Reset the state
				zip: "",        // Reset the zip
				latitude: "",   // Reset the latitude
				longitude: ""   // Reset the longitude
			});
		}
	}	

	handleChangeTimeWithEvent = (newTime, event) => {
		if (event.validationError !== null) {
			return;
		}
		this.setState({ time_of_service: this.dateFormat(newTime, 'HH:MM:ss') });
	}

	sendGATimeEvent = () => {
		if (this.state.time_of_service === '') {
			this.setState({ datetimeError: 'Invalid Date or Time' })
		} else {
			this.setState({ datetimeError: '' })
			if (typeof window !== 'undefined') {
				window.dataLayer.push({
					event: 'ServiceTime'
				});
			}
		}
	}

	handleChangevehicleyear = (event) => {
		this.setState({ vehicleyear: event.target.value })
		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: 'VehicleYear'
			});
		}
	}

	handleChangevehiclename = (event) => {
		this.setState({ vehiclename: event.target.value })
		const price_info = this.state.vehicle_price_details.get(event.target.value).split(',');
		if (this.state.selectedService === 'General Service') {
			this.setState({ price: parseInt(price_info[0])});
		} else if (this.state.selectedService === 'General Service With Oil') {
			this.setState({ price: parseInt(price_info[0]) + parseInt(price_info[1])});
		} else if (this.state.selectedService === 'AMC Plan') {
			this.setState({ price: parseInt(price_info[2])});
		} else if (this.state.selectedService === 'Running Repair') {
			this.setState({ price: 450});
		}
		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: 'VehicleMake'
			});
		}
	}

	handleChangevehiclemileage = (event) => {
		this.setState({ vehiclemileage: event.target.value })
	}

	handleBlurvehiclemileage = (event) => {
		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: 'VehicleMileage'
			});
		}
	}

	priceDisplay = () => {
		if (this.state.selectedService === undefined || this.state.selectedService === "" || this.state.vehiclename === "" || this.state.vehiclename === undefined) {
			return (<div></div>)
		} else {
			return (
				<div>
					<h4 style = {{'text-align': 'center'}}>Rs. {this.state.price}/- only</h4>
				</div>
		)}
	}

	handleChangeservice = (event) => {
		const selectedService = event.target.value;
		this.setState({
			service: event.target.checked,
			serviceName: selectedService,
			selectedService: selectedService,
		});
	
		const selectedVehicle = this.state.vehiclename;
		const selectedVehicleData = this.state.vehicle_data.find(vehicle => vehicle.vehicle_name === selectedVehicle);
	
		const priceInfo = this.getPriceInfo(selectedService, selectedVehicleData);

		this.setState({
			isCODButtonVisible: selectedService !== 'AMC Plan',
			price: priceInfo,
		});

		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				// Remove spaces
				event: selectedService.replace(/\s/g, ''),
			});
		}
	};

	onSuccessfulPayment = (response, data) => {
		const total_amount = parseFloat(data.amount) / 100;
		const service_price = total_amount / 1.18;
		const address = (this.state.homeAddress + ', ' + this.state.locationAddress).replace(/'/g, "\\'");
		const additional_details = this.state.addtnl_details.replace(/'/g, "\\'");
		const params = {
			email: this.state.email,
			first_name: this.state.name.split(' ')[0],
			last_name: this.state.name.split(' ')[1],
			phone: this.state.phone,
			address: address,
			latitude: this.state.latitude,
			longitude: this.state.longitude,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			currency: data.currency,
			vehicle_category: this.state.vehicle_category,
			vehicle_name: this.state.vehiclename,
			vehicle_year: this.state.vehicleyear,
			service_type: this.state.serviceName,
			date_of_service: this.state.date_of_service,
			time_of_service: this.state.time_of_service,
			coupon_code: this.state.coupon,
			service_price: service_price.toFixed(2),
			gst: (total_amount - service_price).toFixed(2),
			total_amount: total_amount.toFixed(2),
			odometer_reading: this.state.vehiclemileage,
			payment_status: 'PAID',
			rzp_order_id: response.razorpay_order_id, 
			rzp_payment_id: response.razorpay_payment_id, 
			rzp_signature: response.razorpay_signature,
			additional_details: additional_details,
			paid_amount: total_amount,
		};

		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: 'Purchase'
			});
		}

		axios.post("https://ridenrepair.com/api/add_booking", params, { headers: { 'Content-Type': 'application/json' } })
		.then((res) => {
			if (res.status === 200) {
				// add user vehicles & user addresses
				if (this.state.isMobileApp) {
					this.addUserAddress();
					this.addUserVehicle();
				}
				if (this.state.isClient && typeof this.ReactPixel.track === 'function') {
					this.ReactPixel.track('Purchase', {value: this.state.price, currency: 'INR'});
				}
				params['booking_id'] = res.data.id;
				if (params.coupon_code.toLowerCase() !== 'offline') {
					axios.post("https://ridenrepair.com/auto/mechanic_allocate", params);
				}
				this.sendMessage(params);
				this.props.history.push({
					pathname: '/thank-you',
					state: { params }
				});
			} else {
				this.setState({ bookingError: 'Payment unsuccessful, please try again!'});
			}
		});
	}

	handleSubmit = async (event) => {
		event.preventDefault();
		// if (this.state.isClient && typeof this.ReactPixel.track === 'function') {
		// 	this.ReactPixel.track('InitiateCheckout');
		// }

		const tasks = [this.validateEmail, this.validatePhone];
		for (const fn of tasks) {
			await fn();
		}

		const data = {
			vehicleyear: this.state.vehicleyear,
			vehicle_category: this.state.vehicle_category,
			vehiclename: this.state.vehiclename,
			vehiclemileage: this.state.vehiclemileage,
			service: this.state.serviceName,
			date_of_service: this.state.date_of_service,
			time_of_service: this.state.time_of_service,
			address: this.state.homeAddress + this.state.locationAddress,
			homeAddress: this.state.homeAddress,
			locationAddress: this.state.locationAddress,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			emailError: this.state.emailError,
			phoneError: this.state.phoneError,
		}

		if (data.vehiclename === undefined || data.service === undefined || data.date_of_service === undefined || data.time_of_service === undefined || data.city === undefined || data.address === undefined ||
				data.vehiclename === '' || data.service === '' || data.date_of_service === '' || data.time_of_service === '' || data.address === '' || data.city === ''|| data.emailError !== '' || data.phoneError !== '') {
			this.setState({ bookingError: 'Please fill in the required fields'});
			return;
		}

		if (this.state.is_phone_verified === false) {
			this.setState({ bookingError: 'Please verify your number before booking!'});
			return;
		}

		if (this.state.is_email_verified === false) {
			this.setState({ bookingError: 'Please verify your email before booking!'});
			return;
		}

		this.setState({ bookingError: ''});

		this.displayRazorpay(this.state.price);
	}

	handleCashPay = async (event) => {
		event.preventDefault();
		this.setState({ booking_in_progress: true });

		if (typeof window !== 'undefined') {
			window.dataLayer.push({ 
				email: this.state.email,
				phone: this.state.phone,
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				city: this.state.city,
				state: this.state.state,
				country: 'IN',
				zip: this.state.zip 
			});
		}

		const tasks = [this.validateEmail, this.validatePhone];
		for (const fn of tasks) {
			await fn();
		}

		// if (this.state.coupon === 'FIRST100' && !this.state.couponApplied) {
		// 	this.setState({ price: (this.state.price - 100).toFixed(0)});
		// 	this.setState({ couponApplied: true});
		// }

		const data = {
			vehicleyear: this.state.vehicleyear,
			vehicle_category: this.state.vehicle_category,
			vehiclename: this.state.vehiclename,
			vehiclemileage: this.state.vehiclemileage,
			service: this.state.serviceName,
			date_of_service: this.state.date_of_service,
			time_of_service: this.state.time_of_service,
			address: this.state.homeAddress + this.state.locationAddress,
			homeAddress: this.state.homeAddress,
			locationAddress: this.state.locationAddress,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			emailError: this.state.emailError,
			phoneError: this.state.phoneError,
			amount: this.state.price,
		}

		if (data.vehiclename === undefined || data.service === undefined || data.date_of_service === undefined || data.time_of_service === undefined || data.city === undefined || data.address === undefined ||
				data.vehiclename === '' || data.service === '' || data.date_of_service === '' || data.time_of_service === '' || data.address === '' ||data.city === '' || data.emailError !== '' || data.phoneError !== '') {
			this.setState({ bookingError: 'Please fill in the required fields'});
			return;
		}

		if (this.state.is_phone_verified === false) {
			this.setState({ bookingError: 'Please verify your number before booking!'});
			return;
		}

		if (this.state.is_email_verified === false) {
			this.setState({ bookingError: 'Please verify your email before booking!'});
			return;
		}

		this.setState({ bookingError: ''});

		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

		let booking_source = '';

		if (isWebView) {
			if (/iPhone|iPod|iPad/.test(window.navigator.userAgent)) {
				booking_source = 'app_ios';
			} else if (/Android/.test(window.navigator.userAgent)) {
				booking_source = 'app_android';
			} else if (/Windows/.test(window.navigator.userAgent)) {
					booking_source = 'app_windows';
			} else {
				booking_source = 'app_unknown';
			}
		} else if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(window.navigator.userAgent)) {
			booking_source = 'mobile_browser';
		} else {
			booking_source = 'desktop_browser';
		}

		const address = (this.state.homeAddress + ', ' + this.state.locationAddress).replace(/'/g, "\\'");
		const additional_details = this.state.addtnl_details.replace(/'/g, "\\'");

		const total_amount = parseFloat(data.amount);
		const service_price = total_amount / 1.18;
		const params = {
			email: this.state.email,
			first_name: this.state.name.split(' ')[0],
			last_name: this.state.name.split(' ')[1],
			phone: this.state.phone,
			address: address,
			latitude: this.state.latitude,
			longitude: this.state.longitude,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			currency: 'INR',
			vehicle_category: this.state.vehicle_category,
			vehicle_name: this.state.vehiclename,
			vehicle_year: this.state.vehicleyear,
			service_type: this.state.serviceName,
			date_of_service: this.state.date_of_service,
			time_of_service: this.state.time_of_service,
			coupon_code: this.state.coupon,
			booking_source: booking_source,
			service_price: service_price.toFixed(2),
			gst: (total_amount - service_price).toFixed(2),
			total_amount: total_amount.toFixed(2),
			odometer_reading: this.state.vehiclemileage,
			payment_status: 'COD',
			rzp_order_id: '', 
			rzp_payment_id: '', 
			rzp_signature: '',
			additional_details: additional_details,
			paid_amount: 0,
		};

		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: 'Purchase'
			});
		}

		axios.post("https://ridenrepair.com/api/add_booking", params, { headers: { 'Content-Type': 'application/json' } })
		.then((res) => {
			if (res.status === 200) {
				// add user vehicles & user addresses
				if (this.state.isMobileApp) {
					this.addUserAddress();
					this.addUserVehicle();
				}
				// ReactPixel.track('Purchase', {value: this.state.price, currency: 'INR'});
				if (this.state.isClient && typeof this.ReactPixel.track === 'function') {
					this.ReactPixel.track('Purchase', {
						content_name: params.service_type,
						value: params.total_amount,
						currency: 'INR',
						em: params.email,
						ph: params.phone,
						fn: params.first_name,
						ln: params.last_name,
						ct: params.city,
						st: params.state,
						country: 'IN',
						zip: params.zip,
					})
				}
				params['booking_id'] = res.data.id;
				if (params.coupon_code.toLowerCase() !== 'offline') {
					axios.post("https://ridenrepair.com/auto/mechanic_allocate", params);
				}
				this.sendMessage(params);
				this.props.history.push({
					pathname: '/thank-you',
					state: { params }
				});
			} else {
				this.setState({ bookingError: 'Payment unsuccessful, please try again!'});
			}
		})
		.catch((error) => {
			// Log error and set error message
			console.log('Error during booking:', error.message);
			this.setState({ bookingError: 'An error occurred, please try again!' });
		})
		.finally(() => {
			this.setState({ booking_in_progress: false }); // Re-enable button and reset text
		});;
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	checkRider = (phone) => {
    return axios.get("https://ridenrepair.com/auto/check_rider", {
      params: {
        phone_number: phone
      }
    }).then(response => {
      const data = response.data;
      return data.exists;
    }).catch(error => {
      console.log('Error checking rider:', error);
      return false;
    });
  }

	handleChangeEmailOTP = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleBlurEmailOTP = (event) => {
		const params = {
			email: this.state.email,
			email_otp: this.state.email_otp
		}
		try {
			axios.post("https://ridenrepair.com/api/validate_email_otp", params, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				if (res.status === 200) {
					if (res.data.is_valid) {
						this.setState({ is_email_verified: true, emailError: '' });
					} else {
						this.setState({ emailError: 'Incorrect Email OTP' })
					}
				}
			});
		} catch (error) {
			console.log('Unable to validate OTP');
		}
	}

	handleChangeBookingOTP = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		const params = {
			phone: this.state.phone,
			booking_otp: event.target.value
		}
		try {
			axios.post("https://ridenrepair.com/api/validate_booking_otp", params, { headers: { 'Content-Type': 'application/json' } })
			.then(async (res) => {
				if (res.status === 200) {
					this.setState({ is_phone_verified: res.data.is_valid });
					if (res.data.is_valid) {
						const riderExists = await this.checkRider(this.state.phone);
						if (riderExists) {
							const { cc_range, service_type } = this.props.location.state;
							if (cc_range === '0-199cc') {
								if (service_type === 'General Service') {
									this.setState({ price: 699 })
								} else if (service_type === 'General Service with Engine Oil') {
									this.setState({ price: 1099 })
								} else {
									this.setState({ price: 400 })
								}
							} else if (cc_range === '200-249cc') {
								if (service_type === 'General Service') {
									this.setState({ price: 749 })
								} else if (service_type === 'General Service with Engine Oil') {
									this.setState({ price: 1349 })
								} else {
									this.setState({ price: 400 })
								}
							}
						}
					}
				}
			});
		} catch (error) {
			console.log('Unable to validate OTP');
		}
	}

	startEmailVerification = async (event) => {
		const tasks = [this.validateEmail];
		for (const fn of tasks) {
			await fn();
		}

		if (this.state.emailError !== '') {
			return;
		}

		if (this.state.email === 'support@ridenrepair.com') {
			this.setState({ is_email_verified: true, emailError: '' });
			return;
		}
		
		const params = {
			email: this.state.email
		}

		axios.post("https://ridenrepair.com/api/send_booking_otp_email", params);
		
		this.setState({ show_enter_email_otp_box: true });
	}

	startVerification = async (event) => {
		const tasks = [this.validatePhone];
		for (const fn of tasks) {
			await fn();
		}

		if (this.state.phoneError !== '') {
			return;
		}
		
		const params = {
			phone: this.state.phone
		}

		axios.post("https://ridenrepair.com/api/send_booking_otp_sms", params);
		
		this.setState({ show_enter_otp_box: true });
	}

	handleBlur = (event) => {
		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: event.target.getAttribute('data-custom-event')
			});
		}
	}

	loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

	displayRazorpay = async (amount) => {
		const res = await this.loadScript(
			"https://checkout.razorpay.com/v1/checkout.js"
		);

		if (!res) {
			alert("Razorpay SDK failed to load. Are you online?");
			return;
		}

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ amount: amount * 100, currency: 'INR' })
		};

		const data = await fetch('https://ridenrepair.com/api/razorpay', requestOptions).then(
			(response) => response.json()
		)

		const options = {
			key: 'rzp_live_v9Y9ZhDUWFgjNI',
			// key: 'rzp_live_JiAxnDUo1qXebr', // Enter the Key ID generated from the Dashboard
			// key: 'rzp_test_K9AJpedBWJr6ah',
			amount: amount,
			currency: data.currency,
			name: 'Ride N Repair',
			description: 'Service Transaction',
				image: 'https://ridenrepair.com/api/logo.png',
				order_id: data.id,
				handler: (response) => {this.onSuccessfulPayment(response, data); },
				prefill: {
					name: this.state.name,
					email: this.state.email,
					contact: this.state.phone,
				},
				notes: {
					address: this.state.homeAddress + this.state.locationAddress,
				},
				theme: {
					color: "#61dafb",
				},
		};
		
		if (typeof window !== 'undefined') {
			const paymentObject = new window.Razorpay(options);
			paymentObject.open();
		}
	}

	setLocationAddress = (locationAddress) => {
		this.setState({ locationAddress: locationAddress });
	}

	setCity = (city) => {
		this.setState({ city: city });
	}

	setStateValue = (state) => {
		this.setState({ state: state });
	}

	setZip = (zip) => {
		this.setState({ zip: zip });
	}

	setLatitude = (latitude) => {
		this.setState({ latitude: latitude });
	}

	setLongitude = (longitude) => {
		this.setState({ longitude: longitude });
	}

	scrollToBookingForm = () => {
		smoothscroll.polyfill();
		if (this.formSection) {
			this.formSection.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}

	componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      // Check if the location has changed (indicating a redirection)
      this.scrollToBookingForm();
    }
  }

	getPriceInfo = (selectedService, vehicleData) => {
		switch (selectedService) {
			case 'General Service':
				return vehicleData.general_service_price;
			case 'General Service With Oil':
				return vehicleData.general_service_price + vehicleData.engine_oil_price;
			case 'AMC Plan':
				return vehicleData.amc_plan_price;
			case 'Running Repair':
				return 450; // Assuming a default value for "Running Repair"
			default:
				return 10000; // Default value if service is not recognized
		}
	};

	addUserVehicle = () => {
		const { vehicle_category, brand, vehicle_name, transmission, fuel_type, cc_range } = this.props.location.state;
		const vehicleData = {
			brand: brand,
			cc_range: cc_range,
			fuel_type: fuel_type,
			model: vehicle_name,
			phone: this.props.user.ph,
			transmission: transmission,
			vehicle_type: vehicle_category
		};
		
		axios.post("https://ridenrepair.com/api/add_user_vehicle", vehicleData)
		.then((res) => {
			if (res.status === 200) {
			} else {
				console.log("Error adding user vehicle");
			}
		});
	}

	addUserAddress = () => {
		const addressData = {
			phone: this.props.user.ph,
			address: this.state.homeAddress,
			latitude: this.state.latitude,
			longitude: this.state.longitude,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip
		};
		
		axios.post("https://ridenrepair.com/api/add_user_address", addressData)
		.then((res) => {
			if (res.status === 200) {
			} else {
				console.log("Error adding user address");
			}
		});
	}

	componentDidMount(){
		
		if (this.state.isClient && typeof this.ReactPixel.track === 'function') {
			this.ReactPixel.track('InitiateCheckout');
		}

		this.setState({ 
			isClient: true,
			serviceName: this.props.location.state.service_type,
			vehiclename: this.props.location.state.vehicle_name,
			vehicle_category: this.props.location.state.vehicle_category,
			price: this.props.location.state.price,
			email: this.props.user.is_logged ? this.props.user.email : '',
			phone: this.props.user.is_logged ? this.props.user.ph : '',
			is_phone_verified: this.props.user.is_logged,
			is_email_verified: this.props.user.is_logged
		});

		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

    if (isWebView) {
			this.setState({
				isMobileApp: true,
				email: this.props.user.email,
				phone: this.props.user.ph,
				name: `${this.props.user.fn} ${this.props.user.ln}`,
				is_phone_verified: this.props.user.is_logged,
				is_email_verified: this.props.user.is_logged
			});

			axios.get("https://www.ridenrepair.com/api/get_user_addresses", {
				params: {
					phone: this.props.user.ph
				}
			})
			.then(response => {
				if (response.status === 200) {
					this.setState({ userAddresses: response.data.addresses });

					const firstAddressIndex = 0;
					if (response.data.addresses.length > 0) {
						// Update state with the index of the first address
						this.setState({ 
							selectedAddress: firstAddressIndex,
							homeAddress: response.data.addresses[firstAddressIndex].address,
							city: response.data.addresses[firstAddressIndex].city,
							state: response.data.addresses[firstAddressIndex].state,
							zip: response.data.addresses[firstAddressIndex].zip,
							latitude: response.data.addresses[firstAddressIndex].latitude,
							longitude: response.data.addresses[firstAddressIndex].longitude
						});
					}
				}
			})
			.catch(error => {
				console.log('Error fetching user addresses:', error);
			});
    }
		
		if (typeof window !== 'undefined') {
			if (localStorage.getItem('phone_provided') !== 'yes' && sessionStorage.getItem('phone_ignored') !== 'yes') {
				this.props.setOpenModal(true);
			}
			import('dateformat').then((dateFormatModule) => {
				this.dateFormat = dateFormatModule.default;
			}).catch((error) => {
				console.log('Unable to load modules');
			});
			import('react-facebook-pixel').then((reactPixelModule) => {
				this.ReactPixel = reactPixelModule.default;
				this.ReactPixel.track('AddToCart');
			}).catch((error) => {
				console.log('Unable to load modules');
			});
			window.dataLayer.push({
				event: 'BookNow'
			});
		}
		
		axios.get("https://ridenrepair.com/api/years", {mode:"no-cors"})
		.then(response => {
			const years = response.data;
			this.setState({ years });
		})
		
		axios.get("https://ridenrepair.com/api/vehicle_name", { mode: "no-cors" })
		.then(response => {
			const vehicle_data = response.data.map(item => ({
				vehicle_name: item.vehicle_name,
				brand_name: item.brand_name,
				vehicle_cc: item.vehicle_cc,
				general_service_price: item.general_service_price,
				engine_oil_price: item.engine_oil_price,
				amc_plan_price: item.amc_plan_price,
			}));

			// Sort by brand, then by CC, and finally by vehicle name
			vehicle_data.sort((a, b) => {
				if (a.brand_name !== b.brand_name) {
					return a.brand_name.localeCompare(b.brand_name);
				}

				if (a.vehicle_cc !== b.vehicle_cc) {
					return a.vehicle_cc - b.vehicle_cc;
				}

				return a.vehicle_name.localeCompare(b.vehicle_name);
			});

			// Extract unique brand names, CC options, and vehicle names
			const brandNames = Array.from(new Set(vehicle_data.map(item => item.brand_name)));
			const ccOptions = Array.from(new Set(vehicle_data.map(item => item.vehicle_cc)));
			const vehicleOptions = Array.from(new Set(vehicle_data.map(item => item.vehicle_name)));

			this.setState({
				vehicle_data,
				brandNames,
				ccOptions,
				vehicleOptions,
				filteredVehicleNames: vehicle_data,
			});
		})
		.catch(error => {
			console.error(error);
		});
		this.scrollToBookingForm();
	}

	handleBrandChange = (event) => {
		const selectedBrand = event.target.value;
		const filteredVehicleNames = this.filterVehicleNames(selectedBrand, this.state.selectedCCRange);
		this.setState({
			selectedBrand,
			filteredVehicleNames,
			vehiclename: "", // Reset selected vehicle when CC range changes
		});
	};

	handleCCRangeChange = (event) => {
		const selectedCCRange = event.target.value;
		const filteredVehicleNames = this.filterVehicleNames(this.state.selectedBrand, selectedCCRange);
		this.setState({
			selectedCCRange,
			filteredVehicleNames,
			vehiclename: "", // Reset selected vehicle when CC range changes
		});
	};

	handleVehicleChange = (event) => {
		const vehiclename = event.target.value;
		this.setState({
			vehiclename,
			serviceName: "",
			selectedService: "",
			isCODButtonVisible: false
		});
	};

	filterVehicleNames = (brand, ccRange) => {
		if (!brand && !ccRange) {
			return this.state.vehicle_data;
		}
	
		return this.state.vehicle_data
			.filter((vehicle) => {
				const brandFilter = !brand || vehicle.brand_name === brand;
				const ccRangeFilter = ccRange === null || this.isVehicleInCCRange(vehicle.vehicle_cc, ccRange);
				return brandFilter && ccRangeFilter;
			})
			.map((vehicle) => ({
				vehicle_name: vehicle.vehicle_name,
				brand_name: vehicle.brand_name,
				vehicle_cc: vehicle.vehicle_cc,
				general_service_price: vehicle.general_service_price,
				engine_oil_price: vehicle.engine_oil_price,
				amc_plan_price: vehicle.amc_plan_price,
			}));
	};	
	
	isVehicleInCCRange = (vehicleCC, ccRange) => {
		let [min, max] = ccRange.split('-').map(Number);
		if (isNaN(min)) {
			min = 801;
			max = Infinity;
		}
		return vehicleCC >= min && (isNaN(max) || vehicleCC <= max);
	};
	
	render() {

		const canonicalUrl = 'https://www.ridenrepair.com/booking';

		return (
			<>
				<Helmet>
					<link rel="canonical" href={canonicalUrl} />
					<title>Book Service - Ride N Repair</title>
					<meta name="description" content="Conveniently book your bike service online with Ride N Repair. Schedule a time, and our expert mechanics will come to your doorstep for seamless repairs and maintenance." />
					<meta name="keywords" content="Online Booking, Ride N Repair, Doorstep Bike Service, Book Bike Service, Schedule Service, Expert Mechanics" />
				</Helmet>

				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}

				{!this.state.isMobileApp && this.props.openModal && <Modal openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} />}
			
				<Header user={this.props.user} setUser={this.props.setUser} isMobileApp={this.props.isMobileApp} />
				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark" style={ this.state.isClient ? { backgroundImage: "url(" + bnrImg + ")" } : {} }>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Booking</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Booking</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>

					<div className="booking-container" ref={ref => (this.formSection = ref)}>
						<section className="section-area section-sp1">
							<div className="container">
								<div className="row">
									<div className="col-lg-8 col-md-7 mb-md-50">
										<form className="booking-form">
											<div className="row">
												
												<div className="col-lg-12">
													<div className="form-group">
														<h6 className="form-title">SELECT EXACT SERVICE ADDRESS</h6>
														<div className="row">
															{this.state.isMobileApp && (
																<div style={{ display: 'flex', justifyContent: 'left' }}>
																	<select 
																		value={this.state.selectedAddress}
																		onChange={this.handleAddressChange}
																		style={{ width: '75%', textAlign: 'center', marginBottom: '5%' }}
																	>
																		<option value="">Choose a new address</option>
																		{this.state.userAddresses.map((address, index) => (
																			<option key={index} value={index}>
																				{address.address}, {address.city}, {address.state} - {address.zip}
																			</option>
																		))}
																	</select>
																</div>
															)}
															{!this.state.isMobileApp || (this.state.isMobileApp && this.state.selectedAddress === "") ? (
																<div className="col-xl-12 col-sm-6">
																	<Places setLocationAddress={this.setLocationAddress} setCity={this.setCity} setStateValue={this.setStateValue} setZip={this.setZip} setLatitude={this.setLatitude} setLongitude={this.setLongitude} isMobileApp={this.state.isMobileApp}/>
																</div>
															) : null}
															{!this.state.isMobileApp || (this.state.isMobileApp && this.state.selectedAddress === "") ? (
																<div className="col-xl-12 col-sm-6" style={{paddingTop: '30px', 'width': '100%'}}>
																	<div className="form-group">
																		<input type="text" name="homeAddress" data-custom-event="homeAddress" className="form-control" placeholder="Complete your address with Flat or Plot No" value={this.state.homeAddress} onChange={this.handleChange} onBlur={this.handleBlur}/>
																	</div>
																</div>
															) : null}
														</div>
													</div>
												</div>

												<div className="col-lg-12">
													<div className="form-group">
														<h6 className="form-title">FILL YOUR INFORMATION</h6>
														<div className="row">
														<div className="col-xl-4 col-sm-6">
																<div className="form-group">
																	<div style={{ display: 'flex' }}>
																		<input
																			type="email"
																			name="email"
																			data-custom-event="EnterEmail"
																			className="form-control"
																			placeholder="Email (for Invoice)"
																			value={this.state.email}
																			onChange={this.handleChange}
																			onBlur={this.handleBlur}
																			disabled={this.state.isMobileApp || this.props.user.is_logged}
																		/>
																		&nbsp;&nbsp;
																		{!this.state.isMobileApp && !this.props.user.is_logged && (
																			<div
																				style={{
																					cursor: 'pointer',
																					textDecoration: 'underline',
																					marginTop: '4%',
																				}}
																				onClick={this.startEmailVerification}
																			>
																				Verify
																			</div>
																		)}
																		&nbsp;&nbsp;
																		{this.state.emailError && <p>{this.state.emailError}</p>}
																	</div>
																</div>
															</div>
															<div className="col-xl-4 col-sm-6">
																<div className="form-group">
																	<div style={{ display: 'flex' }}>
																		<div style={{ paddingTop: '5%' }}>+91&nbsp;</div>
																		<input
																			type="text"
																			name="phone"
																			data-custom-event="EnterPhone"
																			className="form-control"
																			placeholder="Phone Number"
																			value={this.state.phone}
																			onChange={this.handleChange}
																			onBlur={this.handleBlur}
																			disabled={this.state.isMobileApp || this.props.user.is_logged}
																		/>
																		&nbsp;&nbsp;
																		{!this.state.isMobileApp && !this.props.user.is_logged && (
																			<div
																				style={{
																					cursor: 'pointer',
																					textDecoration: 'underline',
																					marginTop: '4%',
																				}}
																				onClick={this.startVerification}
																			>
																				Verify
																			</div>
																		)}
																		&nbsp;&nbsp;
																		{this.state.phoneError && <p>{this.state.phoneError}</p>}
																	</div>
																</div>
															</div>
															<div className="col-xl-4 col-sm-6">
																<div className="form-group">
																	<input
																		type="text"
																		name="name"
																		data-custom-event="EnterName"
																		className="form-control"
																		placeholder="Name (Optional)"
																		value={this.state.isMobileApp ? `${this.props.user.fn} ${this.props.user.ln}` : this.state.name}
																		onChange={this.handleChange}
																		onBlur={this.handleBlur}
																		disabled={this.state.isMobileApp}
																	/>
																</div>
															</div>
															<div className="col-xl-4 col-sm-6">
																{this.state.show_enter_email_otp_box && (
																	<div className="form-group">
																		<input
																			style={{ border: this.state.is_email_verified ? '2px solid green' : '' }}
																			type="text"
																			name="email_otp"
																			data-custom-event="EnterEmailOTP"
																			className="form-control"
																			placeholder="Enter Email OTP"
																			value={this.state.email_otp}
																			onChange={this.handleChangeEmailOTP}
																			onBlur={this.handleBlurEmailOTP}
																		/>
																	</div>
																)}
															</div>
															<div className="col-xl-4 col-sm-6">
																{this.state.show_enter_otp_box && (
																	<div className="form-group">
																		<input
																			style={{ border: this.state.is_phone_verified ? '2px solid green' : '' }}
																			type="text"
																			name="booking_otp"
																			data-custom-event="EnterOTP"
																			className="form-control"
																			placeholder="Enter OTP"
																			value={this.state.booking_otp}
																			onChange={this.handleChangeBookingOTP}
																		/>
																	</div>
																)}
															</div>
															<div className="col-xl-4 col-sm-6"></div>
														</div>
													</div>
												</div>

												<div className="col-lg-12">
													<div className="form-group">
														<h6 className="form-title">SELECT DATE and TIME</h6>
														<p>*Please select a date & time for your service that is at least 30 minutes from the current time.</p>
													</div>
												</div>
												<div className="col-lg-12">
													<div className="col-lg-4">
														<div className="form-group">
															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<div style={{ 'display': 'inline-flex', 'width': '350px' }}>
																	<DatePicker
																		className="custom-calendar"
																		label="Date"
																		value={this.state.date_of_service}
																		onChange={this.handleChangeDateWithEvent}
																		minDate={dayjs()}
																	/>
																	&nbsp;&nbsp;&nbsp;
																	<TimeField
																		className="custom-calendar"
																		label="Time"
																		onChange={this.handleChangeTimeWithEvent}
																		disabled={this.state.date_of_service === undefined || this.state.date_of_service === ''}
																		onBlur={this.sendGATimeEvent}
																	/>
																	{this.state.datetimeError && <p>{this.state.datetimeError}</p>}
																</div>
															</LocalizationProvider>
														</div>
													</div>
												</div>
												<div className="col-lg-12">
													<div className="form-group">
														<h6 className="form-title">ADDITIONAL NOTES</h6>
														<div className="row">
															<div className="col-xl-12 col-sm-6">
																<div className="form-group">
																	<input type="text" name="addtnl_details" data-custom-event="addtnl_details" className="form-control" placeholder="Please explain what is the issue, if any, with your vehicle in detail" value={this.state.addtnl_details} onChange={this.handleChange} onBlur={this.handleBlur}/>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<input name="coupon" data-custom-event="coupon" type="coupon" className="form-control"  placeholder="Discount Coupon" value={this.state.coupon} onChange={this.handleChange} onBlur={this.handleBlur}/>
													</div>
												</div>
												<div className="col-lg-12">
													<p/><div style={{ fontSize: 'x-small' }}><b>*Amount paid will be adjusted from the total bill. If the service is canceled with at least one hour's notice, a full refund will be issued for all charged amounts. All prices are inclusive of GST. If our mechanic comes to your location and no service/repair is performed, there will be a visit charge of Rs 399/-.</b><p/></div>
													<p>{this.state.bookingError}</p>
													<p>
														<Link className="btn btn-primary btn-lg" onClick={this.handleSubmit}>Pay Now</Link>&nbsp;&nbsp;&nbsp;&nbsp;
														{this.state.isCODButtonVisible && (
															<Link 
																className="btn btn-primary btn-lg" 
																onClick={this.handleCashPay} 
																disabled={this.state.booking_in_progress}
															>
																{this.state.booking_in_progress ? 'Booking...' : 'Pay Later'}
															</Link>
														)}
														{/* {this.state.isCODButtonVisible && ( <Link className="btn btn-primary btn-lg" onClick={this.handleCashPay} disabled={this.state.booking_in_progress}>Pay Later</Link> )} */}
													</p>
												</div>
											</div>
										</form>
									</div>
									<div className="col-lg-4 col-md-5">
										<aside>
											<div className="widget">
												<ul className="service-menu style-1">
													<li><Link to="/about">About Us <i className="las la-question-circle"></i></Link></li>
													<li><Link to="/service">Our Services <i className="las la-cog"></i></Link></li>
													<li className="active"><Link to="/booking">Booking<i className="lab la-wpforms"></i></Link></li>
													<li><Link to="/faq">Faq<i className="las la-quote-left"></i></Link></li>
												</ul>
											</div>
										</aside>
									</div>
								</div>
							</div>
						</section>
					</div>



				</div>

				<Footer setCitiesModal={this.props.setCitiesModal} />

			</>
		);
	}
}

export default Booking;
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Images
import pic6 from "../../images/about/optimized_chooseus.webp";

class ChooseUsSection extends Component {
  render() {
    const { cityKey } = this.props;

    let formattedCityKey = '';

    if (cityKey) {
      formattedCityKey = cityKey
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    return (
      <>
        <section className="section-area bg-white section-sp1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-30">
                <div className="choose-media">
                  <LazyLoadImage
                    src={pic6}
                    alt="Why choose Ride N Repair"
                    effect="blur"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="heading-bx mb-30">
                  <h2 className="title mb-0">WHY CHOOSE US</h2>
                  <p>
                    We provide <strong>online bike service</strong> and <strong>two-wheeler service</strong> at your doorstep with just a few clicks. Whether you need a <strong>bike service app</strong> for easy booking or are searching for <strong>doorstep bike repair near me</strong>, we are here to help. Our services are available across major cities like <strong>Bangalore</strong>, <strong>Hyderabad</strong>, and <strong>Chennai</strong>{cityKey && `, including ${formattedCityKey}`}, ensuring quick and reliable <strong>bike repair service</strong> wherever you are.
                  </p>
                  <p>
                    We cater to all popular two-wheelers, including:
                  </p>
                  <ul>
                    <li><strong>Honda Activa</strong></li>
                    <li><strong>Hero Splendor</strong></li>
                    <li><strong>Bajaj Pulsar</strong></li>
                    <li><strong>TVS Jupiter</strong></li>
                    <li><strong>Yamaha FZ</strong></li>
                    <li><strong>Suzuki Access</strong></li>
                    <li><strong>Royal Enfield Classic 350</strong></li>
                    <li><strong>Honda Shine</strong></li>
                    <li><strong>TVS Apache</strong></li>
                  </ul>
                  <p>
                    From regular maintenance to specialized services like <strong>bike dent repair near me</strong>, our expert mechanics provide complete care for your bike.
                  </p>
                  <h4>Why Choose Us?</h4>
                  <ul>
                    <li><strong>Doorstep Bike Service:</strong> We bring the service to your home or office.</li>
                    <li><strong>Online Bike Service Booking:</strong> Book your slot easily with our bike service app or website.</li>
                    <li><strong>Qualified Mechanics:</strong> Whether you need an <strong>Activa mechanic near me</strong> or a Royal Enfield specialist, our highly skilled professionals have you covered.</li>
                    <li><strong>Transparency &amp; Trust:</strong> Get real-time updates and quality repairs for your bike.</li>
                    <li><strong>Emergency Bike Repair:</strong> Looking for a mechanic at doorstep for urgent issues? Our bike repair app makes it simple.</li>
                    <li><strong>Wide City Coverage:</strong> Available in <strong>Bengaluru</strong>, <strong>Pune</strong>, <strong>Delhi</strong>, <strong>Gurugram</strong>, <strong>Noida</strong>, and expanding to other cities.</li>
                  </ul>
                  <p>
                    From <strong>two-wheeler service online</strong> to complete <strong>bike dent repair</strong> and maintenance, we deliver reliable solutions for your bike care needs. Book now and experience a hassle-free <strong>online mechanic for bike</strong> service!
                  </p>
                  {/* <p>
                    We offer a full range of car and bike services at your doorstep{' '}
                    {cityKey && `in ${formattedCityKey}`}.
                    All services are performed by highly qualified mechanics.
                    We also assign a committed service buddy during the time of service to ensure complete service satisfaction.
                  </p> */}
                </div>
                {/* <div className="progress-area">
                  <div className="progress-box style1">
                    <div className="progress-info">
                      <h6 className="title">Quality Services</h6>
                      <h6 className="progress-value">95%</h6>
                    </div>
                    <div className="progress">
                      <div className="progress-bar bg-primary" style={{ width: '96%' }}></div>
                    </div>
                  </div>
                  <div className="progress-box style1">
                    <div className="progress-info">
                      <h6 className="title">Experienced Mechanics</h6>
                      <h6 className="progress-value">85%</h6>
                    </div>
                    <div className="progress">
                      <div className="progress-bar bg-primary" style={{ width: '100%' }}></div>
                    </div>
                  </div>
                  <div className="progress-box style1">
                    <div className="progress-info">
                      <h6 className="title">On-Time Doorstep Service</h6>
                      <h6 className="progress-value">90%</h6>
                    </div>
                    <div className="progress">
                      <div className="progress-bar bg-primary" style={{ width: '98%' }}></div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ChooseUsSection;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, useHistory } from 'react-router-dom'; // Import withRouter
import '../../css/booking-history.css';
import Header from "../layout/header";
import { format } from 'date-fns-tz';

const BookingHistory = ({ user, history, isMobileApp, setUser }) => {
  const [bookings, setBookings] = useState([]);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [copiedReferralCode, setCopiedReferralCode] = useState(false);
  const [invoiceMessage, setInvoiceMessage] = useState('');
  const [bookingStates, setBookingStates] = useState({});

  useEffect(() => {
    // Fetch bookings data
    const fetchData = async () => {
      try {
        let userPhone = user.ph;

        if (!userPhone) {
          // Attempt to fetch from localStorage
          userPhone = localStorage.getItem('userPhone');

          if (!userPhone) {
            const userDetails = localStorage.getItem('userDetails');
            if (userDetails) {
              const data = JSON.parse(userDetails);
              userPhone = data.phone;
              if (userPhone) {
                setUser({is_logged: true, email: data.email, fn: data.first_name, ln: data.last_name, ph: data.phone});
              }
            }
          }
        }

        if (!userPhone) {
          // Redirect to register page
          history.push('/register');
          return;
        }

        // Fetch profile and bookings in parallel
        const [profileRes, bookingsRes] = await Promise.all([
          axios.get(`https://ridenrepair.com/api/get_user_profile?phone=${userPhone}`),
          axios.get(`https://ridenrepair.com/api/get_booking_from_phone?phone=${userPhone}`)
        ]);

        // Update states after both requests resolve
        setProfile(profileRes.data);
        setBookings(Array.isArray(bookingsRes.data) ? bookingsRes.data : []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.ph, setUser, history]);

  const updateBookingState = (bookingId, newState) => {
    setBookingStates(prevState => ({
      ...prevState,
      [bookingId]: newState
    }));
  };

  // Function to send invoice for a specific booking
  const sendInvoice = async (booking_id, email, charges_desc, amount_details, invoice_count) => {
    try {
      await axios.post('https://ridenrepair.com/api/send_invoice', {
        booking_id: booking_id,
        email: email,
        charges_desc: charges_desc,
        amount_details: amount_details,
        invoice_count: invoice_count
      });
      updateBookingState(booking_id, { message: 'Invoice sent successfully.', isDisabled: true });
    } catch (error) {
      updateBookingState(booking_id, { message: error.response?.data?.message || 'Failed to send invoice.', isDisabled: false });
      console.error('Error:', error);
    }

    // Reset message and enable button after 5 seconds
    setTimeout(() => {
      updateBookingState(booking_id, { message: '', isDisabled: false });
    }, 5000);
  };

  const copyReferralCode = () => {
    navigator.clipboard.writeText(profile.referral_code);
    setCopiedReferralCode(true); // Set to true when the code is copied
  
    // Reset to false after 5 seconds
    setTimeout(() => {
      setCopiedReferralCode(false);
    }, 5000);
  };

  if (loading) {
    return (
      <div className='booking-history-parent'>
        <Header user={user} setUser={setUser} isMobileApp={isMobileApp} />
        <div className="booking-history">Loading...</div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className='booking-history-parent'>
        <Header user={user} setUser={setUser} isMobileApp={isMobileApp} />
        <div className="booking-history">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className='booking-history-parent'>
      <Header user={user} setUser={setUser} isMobileApp={isMobileApp} />
      <div className="booking-history">
        <h1>My Profile</h1>
        {profile && (
          <div className="profile-section">
            <p>Hi&nbsp;<strong>{profile.first_name}</strong></p>
            <p><strong>Phone:</strong> {profile.phone}</p>
            <p><strong>Email:</strong> {profile.email}</p>
            <p>
              <strong>Referral Code:</strong> {profile.referral_code} &nbsp;
              <button onClick={copyReferralCode}>Copy</button>
              <br/>
              {copiedReferralCode && <span style={{ color: 'green' }}>Referral Code Copied</span>}
            </p>
            <p>Refer your friends to get Rs 500 per referral once the referred person gets a service from Ride N Repair</p>
            <p><strong>Wallet Balance:</strong> Rs. {profile.wallet_balance || 0}</p>
          </div>
        )}

        <h2>Service History</h2>
        {bookings.length > 0 ? bookings.map((booking, index) => (
          <div key={index} className="booking">
            <h2>{booking.vehicle_name}</h2>
            <p><strong>Service Type:</strong> {booking.service_type}</p>
            <p><strong>Service Date:</strong> {booking.date_of_service}</p>
            <p><strong>Time:</strong> {booking.time_of_service}</p>
            {(booking.final_payment_amount) && (
              <p><strong>Price:</strong> Rs. {booking.final_payment_amount}</p>
            )}
            {format(new Date(), 'yyyy-MM-dd', { timeZone: 'Asia/Kolkata' }) === booking.date_of_service && booking.order_status !== 'CANCELLED' && booking.order_status !== 'DUPLICATE' && booking.order_status !== 'COMPLETED' && booking.order_status !== 'CASH TAKEN' && booking.mechanic_number !== '' && booking.mechanic_number !== undefined && (
              <button 
                onClick={async (e) => {
                  e.preventDefault();
                  history.push({
                    pathname: '/booking-details',
                    search: `actual_booking_id=${booking.booking_id}`
                  });
                }}
              >
                Track your booking
              </button>
            )}
            {(booking.order_status === 'COMPLETED' || booking.order_status === 'CASH TAKEN') && (booking.invoice_count || 0) < 3 && (
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  await sendInvoice(booking.booking_id, user.email, booking.charges_desc, booking.amount_details, booking.invoice_count || 0);
                }}
                disabled={bookingStates[booking.booking_id]?.isDisabled || false}
                className={bookingStates[booking.booking_id]?.isDisabled ? 'disabled-button' : ''}
              >
                Send Invoice
              </button>
            )}
            {bookingStates[booking.booking_id]?.message && <p>{bookingStates[booking.booking_id]?.message}</p>}
            <details>
              <summary>Service Details</summary>
              {booking.charges_desc && booking.amount_details ? (
                <ul>
                  {booking.charges_desc.split(',').map((desc, idx) => (
                    <li key={idx}>
                      <span className="charge-desc">{desc.trim()}</span> - 
                      <span className="charge-amount"> Rs. {booking.amount_details.split(',')[idx].trim()}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No details available.</p>
              )}
            </details>
          </div>
        )): (<p>No service history available.</p>)}
      </div>
    </div>
  );
};

export default withRouter(BookingHistory);
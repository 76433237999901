import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import logo from "../../images/logo_ridenrepair.webp";

class Header extends Component {
  componentDidMount() {
    // Mobile Menu Open
    const menuIcon = document.querySelector(".menuicon");
    const menuLinks = document.querySelector(".menu-links");
    const menuClose = document.getElementById("menuClose");

    if (menuIcon && menuLinks && menuClose) {
      menuIcon.addEventListener('click', this.openMenu);
      menuClose.addEventListener('click', this.closeMenu);
    }

    // Mobile Submenu Open Close Function
    const navMenu = Array.from(document.querySelectorAll('.menu-links > ul > li'));
    navMenu.forEach((item) => {
      item.addEventListener('click', this.toggleSubmenu);
    });
  }

  componentWillUnmount() {
    // Clean up event listeners to prevent memory leaks
    const menuIcon = document.querySelector(".menuicon");
    const menuClose = document.getElementById("menuClose");

    if (menuIcon) {
      menuIcon.removeEventListener('click', this.openMenu);
    }
    if (menuClose) {
      menuClose.removeEventListener('click', this.closeMenu);
    }

    const navMenu = Array.from(document.querySelectorAll('.menu-links > ul > li'));
    navMenu.forEach((item) => {
      item.removeEventListener('click', this.toggleSubmenu);
    });
  }

  openMenu = () => {
    const menuLinks = document.querySelector(".menu-links");
    if (menuLinks) {
      menuLinks.classList.add("show");
    }
  };

  closeMenu = () => {
    const menuLinks = document.querySelector(".menu-links");
    if (menuLinks) {
      menuLinks.classList.remove("show");
    }
  };

  toggleSubmenu = (event) => {
    const current = event.currentTarget;
    const navMenu = Array.from(document.querySelectorAll('.menu-links > ul > li'));
    const isActive = current.classList.contains("open");

    navMenu.forEach((el) => el.classList.remove('open'));

    if (!isActive) {
      current.classList.add('open');
    }
  };

  render() {
    const { isMobileApp } = this.props;

    return (
      <>
        <header className="header header-transparent rs-nav">
          <Sticky enabled={true} className="sticky-header navbar-expand-lg">
            <div className="menu-bar clearfix ">
              <div className="container clearfix">
                <div className="menu-logo">
                  <Link to="/" className="main-logo">
                    <img
                      src={logo}
                      alt="Ride N Repair Brand logo"
                      loading="lazy"
                    />
                  </Link>
                  <Link to="/" className="sticky-logo">
                    <img
                      src={logo}
                      alt="Ride N Repair Brand logo"
                      loading="lazy"
                    />
                  </Link>
                </div>
                <button
                  className="navbar-toggler collapsed menuicon justify-content-end"
                  type="button"
                  data-toggle="collapse"
                  data-target="#menuDropdown"
                  aria-controls="menuDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div className="secondary-menu">
                  <Link
                    to={isMobileApp ? "/register" : "/booking"}
                    className="btn btn-primary ms-3"
                  >
                    Book Now
                  </Link>
                </div>
                <div
                  className="menu-links navbar-collapse collapse justify-content-center"
                  id="menuDropdown"
                >
                  <div className="menu-logo">
                    <Link to="/">
                      <img
                        src={logo}
                        alt="Ride N Repair Brand logo"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <ul className="nav navbar-nav">
                    {isMobileApp && (
                      <li className="active">
                        <Link to="/booking-history">My Profile</Link>
                      </li>
                    )}
                    <li className="active">
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/service">Services</Link>
                    </li>
                    <li>
                      <Link to="/how-we-work">Our Process</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className="active">
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                  <ul className="social-media">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/ridenrepair"
                        rel="noreferrer"
                        className="btn btn-primary"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/ridenrepair"
                        rel="noreferrer"
                        className="btn btn-primary"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="menu-close" id="menuClose">
                    <i className="ti-close"></i>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </header>
      </>
    );
  }
}

export default Header;

import React, { Component } from 'react';

// Images
import googlePlayIcon from '../../images/GooglePlayIcon.webp';
import appStoreIcon from '../../images/AppleAppStoreIcon.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../../css/app-links.css';

class AppLinks extends Component {
  render() {
    const { isMobileApp, cityKey } = this.props;

    let formattedCityKey = '';

    if (cityKey) {
      formattedCityKey = cityKey
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    return (
      <>
        <section className="app-download-section">
          <div className="container">
            {/* ----------------- Referral Message Starts ----------------- */}
          <div className="heading-bx text-center">
            <h2>Refer & Earn ₹500</h2>
            <p className="subtitle">
              Enjoying our on-demand vehicle service? Spread the word and get rewarded! Here’s how:
            </p>
          </div>

          {/* Reusing "app-links-row" & "app-link-card" for steps */}
          <div className="app-links-row">
            <div className="app-link-card" style={{paddingTop: '15px', paddingLeft: '15px', paddingRight: '15px'}}>
              <p>1. Share your unique referral code from your profile with friends.</p>
              <p>2. They receive ₹500 off their first service.</p>
              <p>3. Once they complete their service, ₹500 is automatically credited to you.</p>
            </div>
          </div>

          {/* Additional Note */}
          <div className="heading-bx text-center">
            <p className="subtitle">
              Even if you don’t need repairs now, keep our app for future use. Your referral code is valid whenever friends book!
            </p>
          </div>
            <div className="heading-bx text-center">
              <h2>Download Our App</h2>
              <p className="subtitle">
                Get hassle-free bike & car repair services right at your doorstep{' '}
                {cityKey && `in ${formattedCityKey}`} with our mobile app!
              </p>
            </div>
            {!isMobileApp && !this.props.user.is_logged && (
              <div className="app-links-row">
                <div className="app-link-card">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ridenrepair.app"
                    className="app-link"
                  >
                    <div className="app-icon">
                      <LazyLoadImage
                        src={googlePlayIcon}
                        alt="Google Play Store"
                        effect="blur"
                      />
                    </div>
                    <span className="app-text">Google Play Store</span>
                  </a>
                </div>
                <div className="app-link-card">
                  <a
                    href="https://apps.apple.com/us/app/ridenrepair/id6446174085"
                    className="app-link"
                  >
                    <div className="app-icon">
                      <LazyLoadImage
                        src={appStoreIcon}
                        alt="Apple App Store"
                        effect="blur"
                      />
                    </div>
                    <span className="app-text">Apple App Store</span>
                  </a>
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
}

export default AppLinks;

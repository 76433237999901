import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import ServicesSection from "../elements/services-1";  // Reuse your existing services display
import ChooseUsSection from "../elements/choose-us";

// Import the new Zomato-specific pricing
import zomatoBikePrices from "../../data/zomato_bike_prices.json";
import vehicleData from '../../data/vehicle_data.json'; // but only 'bikeBrands' part is relevant

// The new Zomato modal
import ZomatoRiderModal from '../elements/zomato-rider-modal';

// Optional: If you want to store leads or do something else
import axios from "axios";

class ZomatoRiderServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Force bike as vehicle type
      vehicleType: 'bike',
      brand: '',
      model: '',
      ccRange: '',
      
      // We only have 0-199cc or 200-249cc
      // If you have default brand/model you can set them, else keep them empty
      filteredBikeBrands: [],
      bikeModels: [],
      brandDropdownOpen: false,
      modelDropdownOpen: false,
      isMouseInsideDropdown: false,
      
      // Zomato modal
      openZomatoModal: true,
      // We store the phone/zomatoId data once user enters
      zomatoRider: null
    };

    // 1. If you want these constants within the component:
    this.EXCLUDED_BRANDS = ["Benelli", "BMW", "Ducati", "Harley Davidson", "Husqvarna", "Hyosung", "Kawasaki", "KTM", "Royal Enfield", "Triumph", "UM", "Yezdi"];

    this.top10BikeBrands = [
      'Hero', 'Honda', 'TVS', 
      'Bajaj', 'Royal Enfield', 'Yamaha', 'Suzuki', 
      'KTM', 'Jawa', 'BMW'
    ];

  }

  // 2. Our custom sorting function to prioritize top 10 brands
  customSortByPopularity(brandArray, top10List) {
    return brandArray.sort((a, b) => {
      // Check if either brand is in the top10List
      const aIndex = top10List.indexOf(a.label);
      const bIndex = top10List.indexOf(b.label);

      // If both brands are in the top 10 list, compare their positions in that list
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }
      // If only one brand is in the top 10, prioritize it
      if (aIndex !== -1) return -1;
      if (bIndex !== -1) return 1;

      // Otherwise, fall back to alphabetical sorting
      return a.label.localeCompare(b.label);
    });
  }

  componentDidMount() {
    // By default, do not show any brand/model selection
    // but you can load brand data from vehicleData if needed
    // a) Filter out unwanted brands
    let filteredBikeBrands = vehicleData.bikeBrands.filter(
      (brand) => !this.EXCLUDED_BRANDS.includes(brand.label)
    );

    // b) Sort them by popularity
    filteredBikeBrands = this.customSortByPopularity(filteredBikeBrands, this.top10BikeBrands);

    this.setState({ filteredBikeBrands });

    // c) Build a model list from these filtered+sorted brands
    const allBikeModels = [];
    filteredBikeBrands.forEach((brand) => {
      brand.models.forEach((m) => allBikeModels.push(m));
    });
    
    allBikeModels.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({ bikeModels: allBikeModels });
  }

  handleMouseEnter = (dropdownName) => {
    // close other dropdowns
    Object.keys(this.state).forEach((key) => {
      if (key.endsWith('DropdownOpen') && key !== `${dropdownName}DropdownOpen`) {
        this.setState({ [key]: false });
      }
    });

    // open the hovered dropdown
    this.setState({ [`${dropdownName}DropdownOpen`]: true });
  };

  handleMouseLeave = (dropdownName) => {
    // a short delay to detect if the user moves the mouse to the dropdown options
    setTimeout(() => {
      if (!this.state.isMouseInsideDropdown) {
        this.setState({ [`${dropdownName}DropdownOpen`]: false });
      }
    }, 200);
  };

  handleDropdownOptionsMouseEnter = () => {
    this.setState({ isMouseInsideDropdown: true });
  };

  handleDropdownOptionsMouseLeave = () => {
    this.setState({ isMouseInsideDropdown: false });
  };

  setOpenZomatoModal = (val) => {
    this.setState({ openZomatoModal: val });
  };

  setZomatoRider = (riderObj) => {
    // e.g. riderObj = { phone: '9876543210', zomatoId: 'ZMT12345' }
    this.setState({ zomatoRider: riderObj });
  };

  handleBrandChange = (brand) => {
    const bikeModels = brand.models.slice().sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
    this.setState({ brand, model: '', bikeModels });
  };

  handleDropdownClick = (key, value) => {
    this.setState({ [key]: value });
  };

  shouldDisplayServices = () => {
    // We only require brand, model, ccRange
    const { brand, model, ccRange } = this.state;
    return brand && model && ccRange;
  };

  getSelectedCCData = () => {
    const { ccRange } = this.state;
    return zomatoBikePrices[ccRange] || [];
  };

  render() {
    const {
      vehicleType, brand, model, ccRange, bikeModels,
      openZomatoModal, zomatoRider
    } = this.state;

    const { isMobileBrowser, isMobileApp, user, setUser, openModal } = this.props;
    const canonicalUrl = 'https://www.ridenrepair.com/zomato-rider-services';

    // Filter only the relevant CC ranges
    const ccRangesToShow = ['0-199cc', '200-249cc'];

    return (
      <>
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          <title>Zomato Deliver Partner Bike Services - Ride N Repair</title>
          <meta
            name="description"
            content="Special discounted two-wheeler servicing for Zomato delivery partners. Avail your exclusive prices now!"
          />
        </Helmet>

        {/* Zomato Rider Modal - forcibly open until user enters phone + rider ID */}
        { !localStorage.getItem('zomato_id') && 
          <ZomatoRiderModal
            openZomatoModal={openZomatoModal}
            setOpenZomatoModal={this.setOpenZomatoModal}
            setZomatoRider={this.setZomatoRider}
            setOpenModal={this.props.setOpenModal}
          />
        }

        <Header
          isMobileBrowser={isMobileBrowser}
          isMobileApp={isMobileApp}
          user={user}
          setUser={setUser}
        />

        <div className="page-content bg-white">
          <div
            className="page-banner ovbl-dark vehicle-services-header"
            style={{ backgroundColor: 'black', overflow: 'visible' }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>Zomato Deliver Partner Bike Services</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Zomato Deliver Partner Bike Services
                    </li>
                  </ul>
                </nav>
                <h3 style={{ marginTop: '3%', color: 'white' }}>
                  Choose Your Bike
                </h3>

                <div className="vehicle-selection-row">
                  {/* We don't show Car button: only Bike is possible */}
                  <div className="vertical-dropdown-container">
                    <div className="vehicle-type-buttons specific-vehicle-type">
                      <button
                        className={`vehicle-type-button active`}
                        style={{ cursor: 'not-allowed', opacity: 0.6 }}
                      >
                        Bike
                      </button>
                    </div>

                    {/* Brand selection */}
                    <div 
                      className="vehicle-dropdown"
                      onMouseEnter={() => this.handleMouseEnter('brand')}
                      onMouseLeave={() => this.handleMouseLeave('brand')}
                    >
                      <div
                        className="dropdown-label"
                        onClick={() =>
                          this.setState({
                            brandDropdownOpen: !this.state.brandDropdownOpen,
                          })
                        }
                      >
                        {brand ? (
                          <div className="selected-option">
                            {/* Show brand logo + name */}
                            <img
                              src={require(`../../images/brand_logos/${brand.logo}`)}
                              alt={`${brand.label} brand logo`}
                              className="brand-image selected-image"
                            />
                            <span>{brand.label}</span>
                          </div>
                        ) : (
                          'Select Brand'
                        )}
                        <div className="arrow-down"></div>
                      </div>

                      {this.state.brandDropdownOpen && (
                        <div 
                          className="dropdown-options"
                          onMouseEnter={this.handleDropdownOptionsMouseEnter}
                          onMouseLeave={this.handleDropdownOptionsMouseLeave}
                        >
                          {this.state.filteredBikeBrands.map((b, index) => (
                            <div
                              className="option-item"
                              key={index}
                              onClick={() => {
                                this.handleBrandChange(b);
                                this.setState({ brandDropdownOpen: false });
                              }}
                            >
                              <div className="brand-option">
                                <img
                                  src={require(`../../images/brand_logos/${b.logo}`)}
                                  alt={`${b.label} brand logo`}
                                  className="brand-image"
                                />
                                {b.label}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Model selection */}
                    {brand && (
                      <div 
                        className="vehicle-dropdown"
                        onMouseEnter={() => this.handleMouseEnter('model')}
                        onMouseLeave={() => this.handleMouseLeave('model')}
                      >
                        <div
                          className="dropdown-label"
                          onClick={() =>
                            this.setState({
                              modelDropdownOpen: !this.state.modelDropdownOpen,
                            })
                          }
                        >
                          {model ? model.label : 'Select Model'}
                          <div className="arrow-down"></div>
                        </div>

                        {this.state.modelDropdownOpen && (
                          <div 
                            className="dropdown-options"
                            onMouseEnter={this.handleDropdownOptionsMouseEnter}
                            onMouseLeave={this.handleDropdownOptionsMouseLeave}
                            >
                            {bikeModels.map((m, index) => (
                              <div
                                className="option-item"
                                key={index}
                                onClick={() => {
                                  this.handleDropdownClick('model', m);
                                  this.setState({ modelDropdownOpen: false });
                                }}
                              >
                                {m.label}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                    {/* CC Range selection (only 0-199cc and 200-249cc) */}
                    {brand && model && (
                      <div className="vehicle-type-buttons cc-range-buttons">
                        <div className="cc-range-button-group">
                          {ccRangesToShow.map((ccVal) => (
                            <button
                              key={ccVal}
                              className={`vehicle-type-button cc-range-button ${
                                ccRange === ccVal ? 'active' : ''
                              }`}
                              onClick={() => this.handleDropdownClick('ccRange', ccVal)}
                            >
                              {ccVal}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Optional: show an image if the model has an imagePath */}
                  {model && model.imagePath && (
                    <div className="vehicle-image-container">
                      <img
                        src={require(`../../images/vehicle_images/${model.imagePath}`)}
                        alt={`${vehicleType} ${model.label}`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Show Services Section only if brand+model+ccRange are selected */}
          {this.shouldDisplayServices() && (
            <ServicesSection
              isMobileBrowser={isMobileBrowser}
              isMobileApp={isMobileApp}
              vehicleType={vehicleType}
              model={model}
              brand={brand}
              // We won't use fuelType or transmission here
              fuelType={''}
              transmission={''}
              ccRange={ccRange}
              servicesData={this.getSelectedCCData()}
              user={user}
              setUser={setUser}
              openModal={openModal}
            />
          )}

          <ChooseUsSection
            isMobileBrowser={isMobileBrowser}
            isMobileApp={isMobileApp}
          />
        </div>

        <Footer
          isMobileBrowser={isMobileBrowser}
          isMobileApp={isMobileApp}
          setCitiesModal={this.props.setCitiesModal}
        />
      </>
    );
  }
}

export default ZomatoRiderServices;

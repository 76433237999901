import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import bg2 from "../../images/background/bg1.png";

// Elements
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images
import bnrImg from "../../images/banner/img_aboutbnr.webp";

class PrivacyPolicy extends Component{

	state = {
    isClient: false,
  };

	componentDidMount() {
		this.setState({ isClient: true });
	}
	
	render(){

		const { isClient } = this.state;

		return (
			<>
				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}
				
				<Header user={this.props.user} setUser={this.props.setUser} isMobileApp={this.props.isMobileApp} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={ isClient ? { backgroundImage: "url("+bnrImg+")" } : {} }>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Privacy Policy</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white" style={ isClient ? { backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat" } : {} }>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30 mb-md-50">
								<div className="heading-bx">
									<h4 className="title-ext text-primary">Data Protection and Privacy Policy</h4>
									<p>
										<strong>1. OBJECTIVE:</strong>
										<ul>
												<li className="terms-element-list">
													1.1. RIDE N REPAIR PRIVATE LIMITED (“Company”/ “RNR”/ "We"/ "Us" or "Our") recognizes the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Data Protection and Privacy Policy (“Policy”) describes the terms of processing of digital personal data of individuals, group of individuals, entities and group of entities ("You" or "User"), who upon use of the our platform, consent to submit their Personal Data (as defined below) and how we treat user information collected on platform https://ridenrepair.com/ and other offline sources (“Platform”). This Policy applies to current and former visitors to our website and to our online customers. By consenting to the contents of the Privacy notice and this Policy, you agree to our collection of processing of your data.
														<ol type="a">
															<li className="terms-subelement-list">Visits our RNR App for the purpose of browsing; or</li>
															<li className="terms-subelement-list">Customers who buy Services offered on the RNR App; or</li>
															<li className="terms-subelement-list">Partner brand using our Services; or</li>
															<li className="terms-subelement-list">Users of any system set up on the RNR App to provide a better consumer experience (Assistant / Chats / Pop-ups chat boxes etc.); and</li>
															<li className="terms-subelement-list">Anyone contacting customer-services.</li>
														</ol>
												</li>
												<li className="terms-element-list">1.2. This Policy lays down the norms that govern the use, disclosure and sharing of information about the registered users of the Company.</li>
										</ul>
									</p>
									<p>
										<strong>2. SCOPE AND APPLICABILITY:</strong>
											<ul>
												<li className="terms-element-list">2.1. This Policy applies to all data i.e. representation of information, facts, concepts, opinions or instructions capable of processing or interpreting (“Personal Data”) which the User submits to the platform of the Company with its own free, specific, informed, unconditional and unambiguous consent.</li>
												<li className="terms-element-list">2.2. This Policy aims to be and make the User compliant with relevant statutory and regulatory requirements affecting Data Protection and Data Privacy laws including but not limited to the Digital Personal Data Protection Act 2023.</li>
												<li className="terms-element-list">2.3. The Policy aims to describe the manner in which your Data is stored and processed by us.</li>
												<li className="terms-element-list">2.4. The Company reserves the right to update this Policy and such changes will be posted on this page. Where the Company requires any information in addition to the information stated herein, the Company shall issue a fresh Privacy Notice for the information and consent of the User.</li>
										</ul>
									</p>
									<p>
										<strong>3. WHAT PERSONAL DATA MAY BE COLLECTED:</strong>
										<ul>
												<li className="terms-element-list">
														3.1. We may collect the following Personal Data and Non-Personal Data when you use Platform:
														<ol type="a">
																<li className="terms-subelement-list">
																		a) Personal Information: Personal Information for the purpose of this Privacy Policy includes:
																		<ol type="i">
																				<li className="terms-subsubelement-list">Information provided by you for using our services, such as your Name, mobile number, email, password, date of birth, residential address, gender, marital status, Permanent Account Number (PAN), your bank details, etc.</li>
																				<li className="terms-subsubelement-list">Additional information based on the services obtained from the User as detailed in the Notice and at the discretion of the User.</li>
																				<li className="terms-subsubelement-list">For use of certain services, we may require performance of Know Your Customer registration check and register / update your KYC if not already registered / updated. In such cases, we will require you to provide your KYC information as detailed in 3(a)(i) and 3(a)(ii) mentioned above, upload documentary evidence of your identity, address and financial details and record a live video interaction through the device in use that clearly shows your face to establish liveliness and verify that it’s you who is accessing our application.</li>
																				<li className="terms-subsubelement-list">We may also retrieve your information from SMS. Our RNR App will provide you with a unique time-bound one-time password (“OTP”). This OTP shall be shared via your network carriers SMS services, the OTP will ensure that you have completed a successful registration on the RNR App. We use an OTP based log-in system, which provides security and ease of access to the RNR App.</li>
																				<li className="terms-subsubelement-list">During the use of our services, we will collect information about your transaction history, transaction status and other transaction details.</li>
																				<li className="terms-subsubelement-list">When you access Platform through the mobile application we will receive demographic information and profile data about User’s activity. We identify and use your location, IP address, make, model and the unique identifier of the device in use.</li>
																				<li className="terms-subsubelement-list">When you interact with our customer support systems including through chat bots, email or call we record these interactions.</li>
																		</ol>
																</li>
																<li className="terms-subelement-list">
																		b) Non Personal Information: Non-Personal Information for the purpose of this Privacy Policy includes:
																		<ol type="i">
																				<li className="terms-subsubelement-list">When you open our mobile app, we collect and store information to enable us to provide an online experience that matches your device. The information collected includes the type and address of the device you use, browser and its version, the operating system and its version you are using and the website from which you came to the Platform.</li>
																				<li className="terms-subsubelement-list">When you install and access our mobile applications through a mobile device, we collect information including your location, IP address, make, model and the unique identifier of the device in use, the browser and its version, the operating system installed on the device and its version.</li>
																				<li className="terms-subsubelement-list">When you browse our site or receive an email from us, we and the companies we work with, use cookies and / or pixel tags to collect information and store your online preferences. Cookies are widely used and most browsers are set up to accept them automatically. If you would prefer, you can choose to not accept cookies by disabling the same through browser settings.</li>
																				<li className="terms-subsubelement-list">Cookies and pixel tags held us improve your online experience. This comprises, inter alia, your response to any of our emails, the time and duration of your visits to our site and pages you viewed while on our site.</li>
																				<li className="terms-subsubelement-list">We may also supplement the information we collect with information we receive from other companies. For example, we may use marketing segments developed by us or other companies to customize certain services to your local area and provide relevant offers tailored for you.</li>
																		</ol>
																</li>
																<li className="terms-subelement-list">
																		c) PAYMENT INFORMATION:
																		<br/>
																		If you transact with Us, We collect some additional information, such as a billing address, bank details, credit / debit card number, credit / debit card expiration date and / or other payment instrument details and tracking information from cheques or money orders as the case may be. You will also provide Us with Your payment transaction information if You choose to pay for the Services available on Our RNR App. We will not use Your financial information for any purpose other than to complete a payment transaction with You. To the extent possible, We provide You the option of not divulging any specific information that You wish for Us not to collect, store or use.
																		We use third-party payment processors for processing payments. We do not store your card data on their servers. Your data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing any payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.
																		Our payment gateways adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of credit and debit card information by our store and its service providers.
																		For more insight, you may also want to read terms and conditions of our payment processor partners which can be found <a href="https://RNR.app/terms-of-use">here</a>.
																</li>
														</ol>
												</li>
												<li className="terms-element-list">3.2. RNR may contain link to other websites, however, any information collected by such websites shall not be governed by the Policy of RNR. We shall have no responsibility or control over the practices, policy and content of any third party website. This Policy shall not apply to any information you may disclose to any of our service providers/service personnel which we do not require you to disclose to us or any of our service providers under this Policy.</li>
										</ul>
									</p>
									<p>
										<strong>4. CONSENT FOR PROCESSING PERSONAL DATA:</strong>
										<ul>
												<li className="terms-element-list">
														4.1. The Data Fiduciary i.e. the Company requires processing of the Personal Data of the Data Principal i.e. the User, from time to time for specific purpose. On or before such processing, the Data Fiduciary raises a request to the Data Principal for its consent to such storage and processing which shall be accompanied by Notice containing the following information:
														<ol type="a">
																<li className="terms-subelement-list">4.1.1. The Personal Data which is proposed to be processed;</li>
																<li className="terms-subelement-list">4.1.2. The purpose for which the Personal Data is proposed to be processed;</li>
																<li className="terms-subelement-list">4.1.3. The manner in which the Data Principal may withdraw its consent;</li>
																<li className="terms-subelement-list">4.1.4. The manner in which the Data Principal may access grievance redressal mechanism as provided by the Data Fiduciary in respect of any act or omission of the Data Fiduciary in relation to the Personal Data;</li>
																<li className="terms-subelement-list">4.1.5. The manner in which the Data Principal may make a complaint to the Data Protection Board of India as established under the Digital Personal Protection Act 2023. Hereinafter referred to as “Notice”.</li>
														</ol>
												</li>
												<li className="terms-element-list">4.2. The Data Fiduciary provides the aforesaid Notice in English or any other language as mentioned under Schedule 8 of the Constitution of India for complete understanding and comprehension by the Data Principal.</li>
												<li className="terms-element-list">4.3. The Data Fiduciary only processes the Personal Data of the Data Principal post obtaining consent of the Data Principal in regards to the same.</li>
												<li className="terms-element-list">4.4. Once the Data Principal provides its consent to the processing of the Personal Data, it shall be deemed that the Data Principal has read and understood the contents of the Notice and the consent given thereafter is free, specific, informed, unconditional and unambiguous and shall signify an agreement to the processing of the Personal Data for the purpose as mentioned in the Notice.</li>
												<li className="terms-element-list">4.5. We do not knowingly collect or solicit Personal Information from anyone under the age of 18 or knowingly allow such person to register for the Services on the RNR App without the verifiable consent of a parent of a lawful guardian. If you are under 18, please do not attempt to register for the Services or send any information about yourself to Us. In the event that we learn that we have collected Personal Information from a child under the age of 18, without parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 18, you can contact us at <span class="highlighted-text">[contact details]</span> or our Grievance Officer immediately.</li>
												<li className="terms-element-list">4.6. Where the Personal Data of a person with disability of mind is to be processed, the verifiable consent of a lawful guardian shall be obtained.</li>
										</ul>
								</p>

								<p>
										<strong>5. PURPOSE:</strong>
										<ul>
												<li className="terms-element-list">5.1. The purpose of processing Personal Data under this policy is detailed as under:</li>
												<ol type="a">
														<li className="terms-subelement-list">
																a) Facilitating various services provided by us and the availing of each is at the discretion of the you, including:
																<ol type="i">
																	<li className="terms-subsubelement-list">Providing services to User or;</li>
																	<li className="terms-subsubelement-list">To on-board you on RNR App;</li>
																	<li className="terms-subsubelement-list">Market to you, subject to your consent;</li>
																	<li className="terms-subsubelement-list">To detect and protect the Company against error, fraud, and other criminal activity;</li>
																	<li className="terms-subsubelement-list">To enforce Our Terms, and as otherwise described to You at the time of collection;</li>
																	<li className="terms-subsubelement-list">Setting up your account and facilitating the processing of your transactions through our transaction partner for Mutual Fund services;</li>
																	<li className="terms-subsubelement-list">Setting up and facilitating the processing of your transactions through our partners for domestic equities, insurance if applicable;</li>
																	<li className="terms-subsubelement-list">Enable completion of registration with us and /or with our partners and to process any transaction requests that you authorize, maintain a record of your transactions and holdings, generate and send reminders, alerts, notifications to you for transactions, upcoming funds transfers, etc.</li>
																</ol>
														</li>
														<li className="terms-subelement-list">
																b) Resolving your queries, concerns and to provide you with support in the case of any issues during the use of our services and of those provided by our partners.
														</li>
														<li className="terms-subelement-list">
																c) Informing you about any offers relating to products and services including sharing of marketing material with you.
														</li>
														<li className="terms-subelement-list">
																d) Streamlining and customizing your experience while using the mobile application or while accessing our services through the mobile browser or our website.
														</li>
														<li className="terms-subelement-list">
																e) Informing you over email or on call about the progress of and issues with, if any, pertaining to the transactions initiated by you, and the resolution thereof of relating to the services availed by you.
														</li>
														<li className="terms-subelement-list">
																f) Facilitate the transfer of funds from your bank account to the service provider by validating your bank account and redirecting you to the payment gateway provided by the service provider(s).
														</li>
														<li className="terms-subelement-list">
																g) General business and operating purposes such as the administration, management and operation of the business including client/user interaction, responding to requests, customizing and improving Services;
														</li>
														<li className="terms-subelement-list">
																h) For Payment, billing and invoicing related purposes;
														</li>
														<li className="terms-subelement-list">
																i) To help the Company make decisions on what we should work on next. For example (i) Details of how the User has used Our Services, such as their activity on the Services, and the frequency and duration of their visits to the App (ii) IP Address, (iii) Device event information such as crashes, system activity, hardware settings, browser type, browser language, the date and time of their request and referral URL;
														</li>
														<li className="terms-subelement-list">
																j) To communicate necessary account and service-related information from time to time. To allow you to receive quality customer care services.
														</li>
														<li className="terms-subelement-list">
																k) To tailor the content and information that We may send or display to Users, to offer personalized help and instructions, and to otherwise personalize student experiences while using the RNR App or Services;
														</li>
														<li className="terms-subelement-list">
																l) To better understand how Users access and use Our RNR App and Services, on an aggregated or de-identified basis, in order to improve Our Site, App, and Services and respond to user desires and preferences, and for other research and analytical purposes. To prepare insights to enable us to understand patterns and trends with a view to learn more about your preferences or other characteristics;
														</li>
														<li className="terms-subelement-list">
																m) To provide You with advertising that better suits Your interests and profile and is age-/gender-appropriate and targeted to your general location. To serve and measure the effectiveness of advertising;
														</li>
														<li className="terms-subelement-list">
																n) To track, analyze and maintain information about your use of our application to enable us to maintain and preserve information of your current state and flow progress.
														</li>
														<li className="terms-subelement-list">
																o) To communicate with our partners with respect to their products and services that you may have availed through us.
														</li>
														<li className="terms-subelement-list">
																p) To enable our partners to communicate with you with respect to their products and services that you may have availed through us.
														</li>
														<li className="terms-subelement-list">
																q) To Store and process Personal Data for any other lawful and specified purpose for which the Data Principal has given its consent;
														</li>
														<li className="terms-subelement-list">
																r) Track, analyze and maintain information about you and your use of our services in order to enable us to maintain and preserve data.
														</li>
														<li className="terms-subelement-list">
																s) Processing, under the order of the State or such government body or authorized office of the State having appropriate jurisdiction under any law for the time being in force or compliance of any judgment or order or decree issues under any law for the time being in force;
														</li>
														<li className="terms-subelement-list">
																t) Where the information is required for responding to any medical emergency involving threat to life or immediate threat to health of Data Principal or any other individual;
														</li>
														<li className="terms-subelement-list">
																u) Where information is required to be processed for taking measures to provide medical treatment or health services to any individual during an epidemic, outbreak of disease, or any other threat to public health or for ensuring safety, assistance or services during any disaster or breakdown of public order;
														</li>
														<li className="terms-subelement-list">
																v) Any other lawful purpose as may be detailed under the Notice.
														</li>
												</ol>
												<li className="terms-element-list">5.2. The purpose of processing Non-Personal Data under this policy is detailed as under:</li>
												<ol type="a">
														<li className="terms-subelement-list">a) To improve your experience while using our website, mobile website or mobile application.</li>
														<li className="terms-subelement-list">b) For troubleshooting and identifying any bungs, operational issues, process bottlenecks, errors, in the application and for analyzing usage and activity trends.</li>
														<li className="terms-subelement-list">c) Prepare analyses, reports and tools for your use such as insights.</li>
														<li className="terms-subelement-list">d) To monitor, identify, resolve and prevent and security incidents, frauds, criminal activities, prohibited activities, non-compliances.</li>
														<li className="terms-subelement-list">e) For research, design and development of the products and services offered by us.</li>
														<li className="terms-subelement-list">f) We may use aggregate information that does not identify you individually for better product design, research and for developing customized marketing offers either by us.</li>
												</ol>
												<li className="terms-element-list">5.3. Ride N Repair's use and transfer to any other app of information received from Google APIs will adhere to <a target="_blank" href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use requirements
												</li>
										</ul>
									</p>
									<p>
										<strong>6. DISCLOSURE OF INFORMATION:</strong>
										<ul>
												<li className="terms-element-list">
														6.1. As a matter of policy, we do not sell or rent any personally identifiable information about You to any Third Party. Furthermore, we do not share Personal Information with any Third Parties except in the limited circumstances described in this Privacy Policy. However, The Data Fiduciary is bound to disclose the information in following circumstances:
														<ol type="a">
																<li className="terms-subelement-list">6.2. Service Providers:
																		<ol type="i">
																				<li>We may use third party service providers such as vendors, service providers, partners, etc. to enable us in providing some services to you such as sending e-mail messages to you and tracking them on our behalf, collecting a fee for our services, and providing technical support that can contact you regarding an issue with any feature or functionality on our website.</li>
																				<li>We will not share with, or make available, your personal information to any third party without your authorization, except if we are specifically directed or mandated to do so under any Applicable Law, legal proceeding or directive by any administrative, judicial, quasi-judicial, statutory or regulatory body or any authority or agency of the Government.</li>
																				<li>There may be a number of Services offered by external service providers that help You use Our RNR App. If You choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or grant them permission to collect information about You, then their use of Your information is governed by their privacy policy.</li>
																		</ol>
																</li>
																<li className="terms-subelement-list">6.3. State:
																		<ol type="i">
																				<li>We may require providing Personal Data information to the State or any other authorized government agency as per the applicable law for the time being in force or under any order, decree, judgment of any court having appropriate jurisdiction.</li>
																				<li>The Information provided in such cases shall be limited to the information requested by such authority and shall be provided on “as is” basis.</li>
																				<li>Where, the same is not prohibited under the applicable law or order, we shall notify you of such disclosure.</li>
																		</ol>
																</li>
																<li className="terms-subelement-list">6.4. Analytics:
																		<p>We use analytics services, including mobile analytics software, to help Us understand and improve how the Service is being used. These services may collect, store and use Personal Information in order to help us understand things like how often people/ customers use the Service, the events that occur within the application, usage, performance data, and from where the application was downloaded.</p>
																</li>
																<li className="terms-subelement-list">6.5. Public:
																		<p>We may share aggregated, non-personally identifiable information publicly, including with Users, partners or the press in order to, for example, demonstrate how the RNR App is used, spot industry trends, or to provide marketing materials for the RNR App. Any aggregated information shared this way will not contain any Personal Information.</p>
																</li>
																<li className="terms-subelement-list">6.6. Affiliates:
																		<p>Over time, our RNR App may grow and reorganize. We may share User’s Personal Information with affiliates such as a parent company, subsidiaries, joint venture partners or other companies that we control or that are under common control with us, in which case we will require those companies to agree to use User’s Personal Information in a way that is consistent with this Privacy Policy.</p>
																</li>
																<li className="terms-subelement-list">6.7. Re-organization:
																		<p>In the event of all or a portion of RNR or its assets are acquired by or merged with a third party, Personal Information that we have collected from Users would be one of the assets transferred to or acquired by that third party. This Privacy Policy will continue to apply to Users’ information, and any acquirer would only be able to handle Users’ Personal Information as per this Privacy Policy.</p>
																</li>
														</ol>
												</li>
										</ul>
									</p>

									<p>
										<strong>7. PROTECTION OF PERSONAL INFORMATION:</strong>
										<ul>
												<li className="terms-element-list">7.1. We have implemented reasonable steps to ensure that your Personal Data is kept confidential and secure. Registering for a service on our Platform requires creation of Login ID and Password are the primary means to access our services and website, and hence as a security feature, our website requires creation of a password that meets certain complex requirements. We recommend and require that you keep your Login ID and Password confidential. Further, we recommend that you change your password periodically. We shall not be responsible for any unauthorized access to your account if you share your Login ID and Password with anyone or as a consequence of a violation of this Privacy Policy.</li>
												<li className="terms-element-list">7.2. Every time you login to our website or mobile application, we use, inter alia, the latest authentication and encryption protocols (TLS 1.2) along with session timeouts, firewalls to protect your account from unauthorized access. Your password information is not accessible to anyone including us and can only be changed / updated by you using a two-factor authentication process.</li>
												<li className="terms-element-list">7.3. We may offer the use of social logins or identity providers such as Google, Facebook etc. to enable you to conveniently create an account and register yourselves on our website. By enabling the use of the credentials, we offer you the convenience of not having to create and remember a fresh user ID and password. Such service, its availability and the privacy of your login information are governed by the terms and conditions of the respective service providers. Whilst we do take adequate measures to safeguard your information, we do not take responsibility for your information in case of a security breach at these service providers or in case you share your login information with anyone or in case your social media account was to get hacked. We require you to maintain adequate confidentiality of your social login information and use this facility after due consideration and with appropriate caution.</li>
												<li className="terms-element-list">7.4. When you provide us with your personal information either while setting up an account with us or later, it is kept maintained by the division that is responsible for your account management. You can view and / or update this information by logging on to your account through our app, e-mailing us or calling customer service.</li>
												<li className="terms-element-list">7.5. As we add more services or wish to inform you of some additional benefits that we offer either directly or in partnership with any third party, we may use the information we have collected to offer you customized recommendations as well as marketing communications through any medium including e-mail, SMS, etc. You will have the option to opt out such communications at any time.</li>
										</ul>
									</p>
									<p>
										<strong>8. WITHDRAWAL OF CONSENT/ DELETION OF ACCOUNT:</strong>
										<ul>
												<li className="terms-element-list">8.1. You, the Data Principal (as defined under the Digital Personal Data Protection Act 2023), may withdraw your consent for processing of the Personal Data or delete your account at any time by sending an email to <a href="mailto:info@ridenrepair.com">info@ridenrepair.com</a>.</li>
												<li className="terms-element-list">8.2. Upon withdrawal of the consent by you, we shall, within a reasonable time, cease and cause our data processors to cease processing your Personal Data.</li>
												<li className="terms-element-list">8.3. Where we are bound under the applicable law or by the order of any authority having appropriate jurisdiction to process Personal Data, we shall process such data irrespective of whether the Data Principal withdraws its consent for processing of the Personal Data.</li>
												<li className="terms-element-list">8.4. Where the Data Principal withdraws its consent, the Data Fiduciary shall erase all Personal Data unless the retention is necessary for compliance with any law for the time being in force.</li>
												<li className="terms-element-list">8.5. In the event of deletion of account, the profile of the User shall be deleted from the database of the Platform, which also includes Name, email, DOB, Address, Orders, Payment Details.</li>
												<li className="terms-element-list">8.6. In all matters relating to account deletion, the decision of RNR shall be final and binding and by participating in the offer, the participants agree to be bound by the terms and conditions contained herein.</li>
										</ul>
									</p>

									<p>
										<strong>9. OBLIGATIONS OF THE DATA FIDUCIARY:</strong>
										<ul>
												<li className="terms-element-list">9.1. The Company i.e. the Data Fiduciary is compliant with this policy and the applicable law for the time being in force.</li>
												<li className="terms-element-list">9.2. We have implemented measures to ensure appropriate technical and organizational processes for effective observation of the provisions of Digital Personal Data Protection Act 2023 and rules made thereunder.</li>
												<li className="terms-element-list">9.3. We protect the personal data in its possession or under its control by taking reasonable security safeguards to prevent personal data breach.</li>
												<li className="terms-element-list">9.4. We have implemented measures to erase or cause its data processors to erase such personal data that was made available by you in the event you withdraw your consent or where the specified purpose for which the Personal Data is obtained is no longer being served.</li>
												<li className="terms-element-list">9.5. We shall upon intimation by you of correction, completion or updating of your Personal Data correct such inaccurate Personal Data, complete such incomplete Personal Data or update such Personal Data as the same may be.</li>
												<li className="terms-element-list">9.6. We have implemented measures to respond to any grievance/ complaint/ issue/ query raised by you within a reasonable period of time.</li>
												<li className="terms-element-list">9.7. We do not transfer any Personal Data to such country or territory outside India as may be notified by the Central Government by notification.</li>
										</ul>
									</p>

									<p>
										<strong>10. OBLIGATIONS OF DATA PRINCIPAL:</strong>
										<ul>
												<li className="terms-element-list">10.1. You shall ensure that the information submitted to us is accurate and complete.</li>
												<li className="terms-element-list">10.2. You shall upon any correction, change or update in the Personal Information, notify us of such correction, change or update within a reasonable period of time.</li>
												<li className="terms-element-list">10.3. The Data Principal i.e. you, shall nominate any other individual who shall in the event of death or incapacity of the Data Principal exercise the rights of the Data Principal in accordance with the provisions of this Policy.</li>
												<li className="terms-element-list">10.4. You shall comply with the provisions of all applicable laws for the time being in force.</li>
												<li className="terms-element-list">10.5. You shall not impersonate another person while providing your Personal Data.</li>
												<li className="terms-element-list">10.6. You shall ensure not to suppress any material information while providing your Personal Data for any document, proof of identity or proof of address issued by the State.</li>
												<li className="terms-element-list">10.7. You shall not raise any false or frivolous grievance or complaint.</li>
										</ul>
									</p>

									<p>
										<strong>11. DATA PROTECTION OFFICER:</strong>
										<ul>
												<li className="terms-element-list">11.1. The Data Fiduciary shall appoint a Data Protection Officer the details of which are given hereunder:</li>
												<li className="terms-element-list">Email ID: <a href="mailto:info@ridenrepair.com">info@ridenrepair.com</a></li>
												<li className="terms-element-list">11.2. In the event the personnel appointed as the Data Protection Officer is replaced, the amendment reflecting the same shall be made into the present policy.</li>
												<li className="terms-element-list">11.3. In the event of any grievance faced by you, you can approach the Data Protection Officer, by electronic or physical mode, on the contact information mentioned under this policy and raise its concerns, as the case may be.</li>
										</ul>
									</p>

									<p>
										<strong>12. CONTACT:</strong>
										<ul>
												<li className="terms-element-list">12.1. If you have any concerns in relation to the handling of the personal information or this policy, you can contact us for assistance on the following details:</li>
												<li className="terms-element-list">Email ID: <a href="mailto:info@ridenrepair.com">info@ridenrepair.com</a></li>
										</ul>
									</p>

									<p>
										<strong>13. SEVERABILITY:</strong>
										<ul>
												<li className="terms-element-list">13.1. Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall have no effect on the remaining paragraphs of this Privacy Policy.</li>
										</ul>
									</p>

									<p>
										<strong>14. DO NOT TRACK:</strong>
										<ul>
												<li className="terms-element-list">14.1. The Service or RNR App is not designed to respond to “do not track” signals sent by some browsers.</li>
												<li className="terms-element-list">14.2. Advisory Note: This Privacy Policy is published in compliance with inter alia:</li>
												<li className="terms-element-list">• Section 43A of the Information Technology Act, 2000; and</li>
												<li className="terms-element-list">• Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (“SPDI Rules”).</li>
												<li className="terms-element-list">• THE DIGITAL PERSONAL DATA PROTECTION ACT, 2023</li>
										</ul>
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default PrivacyPolicy;
import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import bg2 from "../../images/background/bg1.png";

// Images
import bnrImg from "../../images/banner/img_aboutbnr.webp";

class ThankYouEmail extends Component{
	
	render(){

		return (
			<>
				
				<Header user={this.props.user} setUser={this.props.setUser} isMobileApp={this.props.isMobileApp} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Thank You</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Thank You</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white" style={{backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat"}}>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30 mb-md-50">
								<div className="heading-bx">
									<h4 className="title-ext text-primary">Thank You</h4>
									<p>
										Thank you for reaching out to Ride N Repair. We will be responding to your query within 24 hours. Looking forward to making your experience better.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default ThankYouEmail;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images
import bnrImg from "../../images/banner/bnr3.jpg";

class Faq extends Component {
  render() {
    const { citiesModal, setCitiesModal, isMobileBrowser, isMobileApp, user, setUser } = this.props;
    const canonicalUrl = 'https://www.ridenrepair.com/faq';

    return (
      <>
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          <title>FAQ - Ride N Repair</title>
          <meta
            name="description"
            content="Explore frequently asked questions about Ride N Repair. Find answers related to our services, booking process, pricing, and more. Your questions, answered!"
          />
          <meta
            name="keywords"
            content="FAQ, Ride N Repair, Frequently Asked Questions, Services, Book Service, Doorstep Service, Bike Service, Two-wheeler Service, Vehicle Service"
          />
        </Helmet>

        {citiesModal && <AvailableCitiesModal closeModal={() => setCitiesModal(false)} />}

        <Header user={user} setUser={setUser} isMobileApp={isMobileApp} />

        <div className="page-content bg-white">
          {/* Page Banner */}
          <div
            className="page-banner ovbl-dark"
            style={{ backgroundImage: `url(${bnrImg})` }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>FAQ's</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      FAQ's
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          {/* FAQ Content */}
          <section className="section-area section-sp3">
            <div className="container">
              <h2 className="mb-4">Frequently Asked Questions (FAQs)</h2>
              <Accordion defaultActiveKey="0" className="accordion ttr-accordion style1">

                {/* 1. What is Ride N Repair? */}
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    What is Ride N Repair?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Ride N Repair is an online bike service platform that provides doorstep 
                      two-wheeler repair and maintenance services. You can book services through 
                      our bike service app or website, and a professional mechanic will come to 
                      your location.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 2. How to Book service? */}
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    How to Book service?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      You can book the service through our website by selecting the vehicle, 
                      date, slot, and address—or by emailing us at <strong>info@ridenrepair.com</strong>.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 3. How can I book a bike service? (Alternative wording) */}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    How can I book a bike service?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      You can easily book a service online via our bike service app or website. 
                      Simply choose your location, vehicle model (e.g., Honda Activa, Hero Splendor, 
                      Bajaj Pulsar), and preferred time slot.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 4. How much time does it take for the complete two-wheeler service at the doorstep? */}
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    How much time does it take for the complete two-wheeler service at the doorstep?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      It usually takes 90-120 minutes for the complete service. In case of spare 
                      replacement or any major issue, it can go beyond the mentioned time.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 5. Why doorstep service should be preferred over garages/workshops? */}
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    Why doorstep service should be preferred over garages/workshops?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      It's a myth that two-wheelers are better serviced at garages/workshops. 
                      Doorstep service offers more transparency, zero waiting time, and direct 
                      interaction with trained technicians for your queries.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 6. Do you provide any vehicle service warranty? */}
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    Do you provide any vehicle service warranty?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      We offer a 10-day service warranty. Spares and consumables are charged 
                      separately in case of replacement.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 7. Can I book the bike service at my office place? */}
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    Can I book the bike service at my office place?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Yes, you can. Just ensure there's sufficient space for the technician to 
                      work on your two-wheeler.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 8. How can I make the payment? */}
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    How can I make the payment?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      We accept payments through online links, Google Pay, UPI, and debit/credit cards. 
                      We also accept cash, but the 10-day service warranty is not applicable for cash payments.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 9. How do I pay for the service? (Alternative wording) */}
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    How do I pay for the service?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      You can pay securely online using multiple payment options, including UPI, 
                      credit/debit cards, and net banking. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 10. How do I get my bill after the service? */}
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    How do I get my bill after the service?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The bill will be emailed to you at the address used during sign-up.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 11. What parts should be replaced at what intervals? */}
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    What parts should be replaced at what intervals?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Replacement intervals vary by model and usage. Common replacements include 
                      engine oil every 2,000-3,000 km, air filter cleaning/replacement at regular 
                      intervals, and brake pads when worn out. Always follow manufacturer guidelines 
                      for best performance.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 12. Do you provide doorstep bike repair in all cities? */}
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    Do you provide doorstep bike repair in all cities?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      We currently offer doorstep bike repair in major cities like Bangalore, 
                      Hyderabad, and Chennai, and we are expanding to other cities soon.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 13. What types of bikes and scooters do you service? */}
                <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    What types of bikes and scooters do you service?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>We service all popular two-wheeler brands and models, including:</p>
                    <ul>
                      <li>Honda Activa</li>
                      <li>Hero Splendor</li>
                      <li>Bajaj Pulsar</li>
                      <li>TVS Jupiter</li>
                      <li>Yamaha FZ</li>
                      <li>Royal Enfield Classic 350</li>
                      <li>Suzuki Access</li>
                      <li>Honda Shine</li>
                      <li>TVS Apache</li>
                      <li>KTM Duke</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 14. What services do you offer? */}
                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    What services do you offer?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>We provide a full range of services, including:</p>
                    <ul>
                      <li>General Bike Service</li>
                      <li>Bike Dent Repair</li>
                      <li>Emergency Bike Repair</li>
                      <li>Two-Wheeler Maintenance</li>
                      <li>Oil Change and Filter Replacement</li>
                      <li>Brake and Clutch Repair</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 15. What if I need an emergency repair? */}
                <Accordion.Item eventKey="14">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    What if I need an emergency repair?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      If you need urgent assistance, our mechanic at doorstep will arrive at your 
                      location quickly. Simply use our bike repair app to book an emergency service.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 16. Are your mechanics qualified and trustworthy? */}
                <Accordion.Item eventKey="15">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    Are your mechanics qualified and trustworthy?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Yes, all our mechanics are professionally trained, background-verified, and 
                      equipped to handle two-wheeler repairs for various brands and models.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 17. Can I track the progress of my service? */}
                <Accordion.Item eventKey="16">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    Can I track the progress of my service?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Absolutely! You can track real-time service updates through our bike service 
                      online booking platform or app.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 18. Why should I choose Ride N Repair? */}
                <Accordion.Item eventKey="17">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    Why should I choose Ride N Repair?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>With Ride N Repair, you get:</p>
                    <ul>
                      <li>Convenient doorstep service</li>
                      <li>Professional and verified mechanics</li>
                      <li>Easy online booking</li>
                      <li>Transparent pricing</li>
                      <li>Expert care for all two-wheeler models</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* 19. Can I reschedule my appointment? */}
                <Accordion.Item eventKey="18">
                  <Accordion.Header>
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    Can I reschedule my appointment?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Yes, you can reschedule by contacting our support or via the app. We understand 
                      that plans can change, and we strive to make your experience as flexible as possible.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>
            </div>
          </section>
        </div>

        <Footer setCitiesModal={setCitiesModal} />
      </>
    );
  }
}

Faq.propTypes = {
  isMobileBrowser: PropTypes.bool,
  isMobileApp: PropTypes.bool,
  user: PropTypes.object,
  setUser: PropTypes.func,
  citiesModal: PropTypes.bool,
  setCitiesModal: PropTypes.func
};

export default Faq;
